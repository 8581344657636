import Dragging from "../controls/dragging";
import Selection from "../controls/selection";
import * as THREE from "three";
import * as C from "../constants";
import {interpretRotation} from "../models/helpers";
import {last} from "ramda";

let lastLookAt, lastDir ;

export function camera ({
                            position = { x : null, y : null, z : null},
                            zoom = { near: null, far: null },
                            orto
                        }
                            , that) {


    const { x = null, y = null, z = null } = position;
    that.camera.position.x = typeof x == 'number' ? x : that.camera.position.x;
    that.camera.position.y = typeof y == 'number' ? y : that.camera.position.y;
    that.camera.position.z = typeof z == 'number' ? z : that.camera.position.z;

    const { far, near } = zoom;
    that.camera.near = typeof near == 'number' ? near : that.camera.near;
    that.camera.far = typeof far == 'number' ? far : that.camera.far;

    const n = typeof near == 'number' ? near : C.NEAR;
    const f = typeof near == 'number' ? far : C.FAR;
    that.orbitControls.minDistance = n ;
    that.orbitControls.maxDistance = f ;

    if(orto){
        const d = orto.distance || lastLookAt || C.LOOKAT_DISTANCE
        lastLookAt = orto.distance || lastLookAt;
        lastDir = orto.direction || lastDir;

        const dir = orto.direction || lastDir;
        switch(dir){
            case "front":  {
                that.camera.position.set(0,0, -1 * d); return;
            }
            case "back":  {
                that.camera.position.set(0,0, d); return;
            }
            case "left": {
                that.camera.position.set(-1 * d, 0,0); return;
            }
            case "right": {
                that.camera.position.set(d,0, 0); return;
            }
            case "top": {
                that.camera.position.set(0, d, 0); return;
            }
            default: that.camera.position.set(0,0, -1 * d)
        }

        that.camera.lookAt(0,0,0);
    }

}

export function dragging({ models, highlight, mode }, that){

    that.dragging = new Dragging[mode || "default"](models, highlight);

    that.dragPlane = new THREE.Plane();
    that.dragPNormal = new THREE.Vector3(0, 1, 0); // plane's normal
    that.dragPlaneIntersect = new THREE.Vector3(); // point of intersection with the plane
    that.dragPIntersect = new THREE.Vector3(); // point of intersection with an object (plane's point)
    that.shift = new THREE.Vector3(); // distance between position of an object and points of intersection with the object

    that.dragPlaneY = new THREE.Plane();
    that.dragPNormalY = new THREE.Vector3(0, 0, 1); // plane's normal
    that.dragPlaneIntersectY = new THREE.Vector3(); // point of intersection with the plane
    that.dragPIntersectY = new THREE.Vector3(); // point of intersection with an object (plane's point)
    that.shiftY = new THREE.Vector3(); // distance between position of an object and points of intersection with the object

}

export function selection({ selected, highlight, models }, that){
    that.selection = new Selection(selected, highlight, models);
    if(selected) that.selection.selectCurrent(that);
}
