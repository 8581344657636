import ThreeD from "./ThreeD";

export default class ComponentLoader {
    /**
     * Read definitions from json
     * @param {Object} json
     * @returns {{children: Object[], name: string, locals: *}[]}
     */
    static compileJson(json) {
        return Object.keys(json).map((name) => {
            const { children: objectChildren = [], locals = {} } = json[name];
            const children = objectChildren.map((child) => {
                const type = Object.keys(child)[0];
                const definition = child[type];
                return {
                    type,
                    ...definition,
                };
            });
            return {
                name,
                locals,
                children,
            };
        });
    }

    /**
     * Load component definitions from json
     * @param {Object} data - Json with definitions
     */
    static load(data) {
        const definitions = this.compileJson(data);
        definitions.forEach((definition) => {
            const { name, locals, children } = definition;
            ThreeD.createComponent(name, locals, ...children);
        });
    }
}
