export const Cuboid = {
    kind: "Primitive",
    primitive: "Cuboid",
    geometry: {
        depth: 100,
        height: 200,
        width: 400,
    },
    material: {
        color: "'#ff0000'",
    },
};

export const CuboidTransparent = {
    kind: "Primitive",
    primitive: "Cuboid",
    geometry: {
        depth: 100,
        height: 200,
        width: 400,
    },
    material: {
        color: "'#ff0000'",
        opacity: .5,
    },
};

export const CuboidOriginNil = {
    kind: "Primitive",
    primitive: "Cuboid",
    geometry: {
        depth: 100,
        height: 200,
        width: 400,
    },
    material: {
        color: "'#ff0000'",
    },
    origin: "'Nil'",
};

export const CuboidOriginBaseCenter = {
    kind: "Primitive",
    primitive: "Cuboid",
    geometry: {
        depth: 100,
        height: 200,
        width: 400,
    },
    material: {
        color: "'#ff0000'",
    },
    origin: "'BaseCenter'",
}
