import React from "react";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
} from "@apollo/client";

import JustEngine from "./engine/App";
import Administration from "./administration/index";
import Dev from "../dev/Dev";
import Dev2 from "../dev2";

const client = new ApolloClient(
    {
        cache: new InMemoryCache(),
        uri: `${ window.location.origin }/graphql`,
    },
);

export default function App() {
    if (window.location.pathname.startsWith("/admin")) {
        return (
            <ApolloProvider client={ client }>
                <Administration />
            </ApolloProvider>
        );
    }
    if (window.location.pathname.startsWith("/dev2")) {
        return (
            <ApolloProvider client={ client }>
                <Dev2 />
            </ApolloProvider>
        );
    }
    if (window.location.pathname.startsWith("/dev")) {
        return (
            <ApolloProvider client={ client }>
                <Dev2 />
            </ApolloProvider>
        );
    }
    return <JustEngine />;
}
