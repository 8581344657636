import TemplateInterpreter from "../core/TemplateInterpreter";
import * as evaluation from "../helpers/evaluation";
import AbstractObjectFactory from "../core/AbstractObjectFactory";
import { GroupBuilder } from "./Group";

export default class ReferenceInterpreter extends TemplateInterpreter {

    static async interpret(template, context, previTrans, Library, interpret, key) {
        const referencedTemplate = await Library.loadTemplate(template.template);
        const evaluatedParameters = evaluation.evaluateExpressionMap(template.parameters, context);
        const transformations = this.interpretTransformations(template, context, previTrans);
        const ref = await interpret(referencedTemplate, evaluatedParameters, previTrans, Library);
        return new ReferenceFactory(ref, transformations, key);
    }

}

class ReferenceFactory extends AbstractObjectFactory {

    constructor(readonly s, readonly t, readonly k) {
        super();
    }

    * generate(container) {
        const reference = new GroupBuilder({ name: "ref" }, this.t, this.k);
        yield {
            object: reference,
            container,
        };
        yield {
            object: this.s,
            container: reference.object,
        };
    }

}
