import * as R from "ramda";

/**
 * Regex for matching identifiers
 * @type {RegExp}
 */
export const TOKEN_REGEX = /('?[a-zA-Z_][a-zA-Z0-9_.]*'?)/gm;

/**
 * JavaScript reserved keywords that are not valid variable identifiers
 * @type {string[]}
 */
export const RESERVED_KEYWORDS = [
    "abstract",
	"arguments",
	"await",
	"boolean",
    "break",
	"byte",
	"case",
	"catch",
    "char",
	"class",
	"const",
	"continue",
    "debugger",
	"default",
	"delete",
	"do",
    "double",
	"else",
	"enum",
	"eval",
    "export",
	"extends",
	"false",
	"final",
    "finally",
	"float",
	"for",
	"function",
    "goto",
	"if",
	"implements",
	"import",
    "in",
	"instanceof",
	"int",
	"interface",
    "let",
	"long",
	"native",
	"new",
    "null",
	"package",
	"private",
	"protected",
    "public",
	"return",
	"short",
	"static",
    "super",
	"switch",
	"synchronized",
	"this",
    "throw",
	"throws",
	"transient",
	"true",
    "try",
	"typeof",
	"var",
	"void",
    "volatile",
	"while",
	"with",
	"yield",
];

/**
 * Extract variable identifiers from function declaration
 * @param {string} formula - Function declaration
 * @returns {string[]} Identifiers
 */
export function getIdentifiers(formula) {
    const allTokens = formula.toString().match(TOKEN_REGEX);
    if (!allTokens) return [];
    return Array.from(new Set(allTokens))
        .filter((token) => !token.includes("'"))
        .filter((token) => !token.startsWith("Math."))
        .filter((token) => !RESERVED_KEYWORDS.includes(token));
}

/**
 * Convert function declaration to function
 * @param {string} formula - Function declaration
 * @returns {function(Object.<string, string | number>): *}
 */
export function createFunction(formula) {
    const identifiers = getIdentifiers(formula);
    const fn = new Function(...identifiers, `return ${formula};`);
    return function executor(context) {

        const args = identifiers.map((identifier) => context[identifier]);
        return fn(...args);
    };
}

/**
 * Evaluate declarations with context
 * @param {Object.<string, string | number>} declarations
 * @param {Object.<string, string | number>} context
 * @return {Object.<string, string | number>} Values
 */
export function excuteDeclarationsWithContext(declarations, context) {

    return R.map((formula) => {
        return createFunction(formula)(context)
    }, declarations);
}

/**
 * Generate number series
 * @generator
 * @param {number} start - Series start
 * @param {number} end - Series end
 * @param {number} step - Series step
 * @yields {number} Next number in series
 */
export function* range(start, end, step) {
    for (let n = start; n <= end; n += step) {
        yield n;
    }
}

/**
 * @typedef {Object} IteratorDefinition
 * @property {string | number} start
 * @property {string | number} end
 * @property {string | number} step
 */

/**
 * Generate number series from iterator definition
 * @generator
 * @param {IteratorDefinition} definition
 * @param {Object.<string, string | number>} context
 * @yields {number} Next number in series
 */
export function* iteration(definition, context) {
    const { start, end, step } = excuteDeclarationsWithContext(definition, context);
    yield* range(start, end, step);
}

/**
 * Creates sequence over iterators
 * @param {Object.<string, IteratorDefinition>} iterators
 * @param {Object.<string, string | number>} context
 * @return {Object.<string, number>[]} Iteration values
 */
export function expandIterators(iterators, context) {
    const iterations = R.map((definition) => iteration(definition, context), iterators);
    const namedIterations = R.mapObjIndexed((series, key) => Array.from(series).map((n) => ({ [key]: n })), iterations);
    return Object.values(namedIterations).reduce(
        (a, iteration) => a.map((a) => iteration.map((b) => ({ ...a, ...b }))).flat(),
        [{}]
    );
}
