import type { Object3D } from "three";
import { Group } from "three";

import { createContext } from "./helpers/context";
import { selectInterpreter } from "./interpreters";
import AbstractObjectBuilder from "./core/AbstractObjectBuilder";
import AbstractObjectFactory from "./core/AbstractObjectFactory";
import GarbageCollector from "./GarbageCollector";

export async function interpret(template, parameters, transformations, load, key) {
    const context = createContext(template, parameters);
    const interpreter = selectInterpreter(template, context);
    const sContext = { ...context };
    delete sContext["_ITERATORS"];
    return interpreter.interpret(template, sContext, transformations, load, interpret, key);
}

function draw(builder: AbstractObjectBuilder, container: Object3D): Object3D {
    let object;
    // object = builder.findHost(container);
    if (object) {
        builder.apply(object);
    } else {
        object = builder.construct();
        if (object) {
            container.add(object);
        }
    }
    if (object) {
        builder.applyTransformations(object);
    }
    // console.log("draw", object);
    return object;
}

export function render(builder: AbstractObjectFactory | AbstractObjectBuilder, container: Object3D) {
    console.groupCollapsed("render", { builder, container });

    const gcEnabled = GarbageCollector.isEnabled;
    if (!gcEnabled) GarbageCollector.enable();

    if (builder instanceof AbstractObjectBuilder) {
        builder.object = draw(builder, container);
        GarbageCollector.protect(builder.object, container);
    }
    if (builder instanceof AbstractObjectFactory) {
        for (const y of builder.generate(container)) {
            render(y.object, y.container);
            builder.bs.push(y.object);
        }
    }

    if (!gcEnabled) GarbageCollector.collect();

    console.groupEnd();
}

export function getRenderTarget(renderRoot: Object3D, renderID: string): Object3D {
    const existingTarget = renderRoot.children.find((object) => object.userData.renderID === renderID);
    if (existingTarget) return existingTarget;

    const target = new Group();
    target.name = `Render Target (renderID: ${ renderID })`;
    target.userData.renderID = renderID;
    renderRoot.add(target);
    return target;
}
