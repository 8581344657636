import Scene from "./scene";

export default class Facade {

    scenes = {};

    addScene(key, scene){
        this.scenes[key] = new Scene(scene);
    }

    changeScene(key, d){
        this.scenes[key] ? this.scenes[key].applyChange(d) : this.scenes[key] = new Scene(d) ;
    }

    setNotification(notify){

        Object.values(this.scenes).forEach(s => s.setNotification((sceneConf) => {
            // get current config from all scenes + this one
            const conf = {
                "scenes" : {...Object.values(this.scenes).map(s => s.getConfig())
                        .reduce((o, el) => ({...el, ...o}) , {}), ...sceneConf}
            }
            notify(conf);
        }))
    }

    finalize(){
        Object.values(this.scenes).forEach(s => s.finalize());
        this.scenes = {};
    }

}
