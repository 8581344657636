import styled from "@emotion/styled";

export const Main = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    margin:0;
    width: 100%;
`;

export const Edit = styled.div`
    display: flex;
    flex: none;
    flex-direction: column;
    width: 450px;
`;

export const TextEditor = styled.div`
    textarea {
        height: 80vh;
        padding: 0;
        width: 100%;
    }
`;

export const Catalogue = styled.div`
    width: 20vw;
    .paginate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const Mdl = styled.div`
    margin: 15px;

    &:hover {
        color: #555bfc;
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ModelCard = styled.div`
    background-color: aliceblue;
    overflow:auto;

    table {
        max-height: 300px;
        overflow-y: scroll;
        width: 100%;
    }

    tr.highlight {
        background-color: white;
    }

    .name {
        display: flex;
        flex-direction: row;
        font-size: 18px;
        justify-content: space-between;
    }
`;

