import * as evaluation from "../helpers/evaluation";

export default abstract class TemplateInterpreter {

    static interpretTransformations(template, context, pt) {
        const rotation = evaluation.evaluateExpressionMap(template.rotation, context);
        const translation = evaluation.evaluateExpressionMap(template.translation, context);
        return {
            rotation: evaluation.stackTransformations(pt?.rotation, rotation),
            translation: evaluation.stackTransformations(pt?.translation, translation),
        };
    }

}

