import type { Object3D } from "three";

import type AbstractObjectBuilder from "./AbstractObjectBuilder";

interface IRenderCall {
    object: AbstractObjectBuilder
    container: Object3D
}

export default abstract class AbstractObjectFactory {

    bs = [];

    abstract generate(container: Object3D): Generator<IRenderCall>

    * overBS() {
        for (const b of this.bs) {
            if (b instanceof AbstractObjectFactory) {
                yield* b.overBS();
            } else {
                yield b;
            }
        }
    }

}
