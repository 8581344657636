import { dragModes } from "../constants";

class Drag {

    active = false;

    constructor(models, highlight) {
        this.models = models;
        this.highlight = highlight;
    }

    move(dragObject, dragPlaneIntersect, shift){
        dragObject.position.addVectors(dragPlaneIntersect, shift);
    }
}

class LinearX extends Drag {
    move(dragObject, dragPlaneIntersect, shift){
        const tmp = JSON.parse(JSON.stringify(dragObject.position));

        dragObject.position.addVectors(dragPlaneIntersect, shift);
        dragObject.position.y = tmp.y;
        dragObject.position.z = tmp.z;

    }
}

class LinearY extends Drag {
    move(dragObject, dragPlaneIntersect, shift, dragPlaneIntersectY, shiftY){
        const tmp = JSON.parse(JSON.stringify(dragObject.position));

        dragObject.position.addVectors(dragPlaneIntersectY, shiftY);
        dragObject.position.x = tmp.x;
        dragObject.position.z = tmp.z;

    }
}

class LinearZ extends Drag {
    move(dragObject, dragPlaneIntersect, shift){
        const tmp = JSON.parse(JSON.stringify(dragObject.position));

        dragObject.position.addVectors(dragPlaneIntersect, shift);
        dragObject.position.y = tmp.y;
        dragObject.position.x = tmp.x;
    }
}

class PlaneDragXZ extends Drag {
    move(dragObject, dragPlaneIntersect, shift){
        const tmp = JSON.parse(JSON.stringify(dragObject.position));

        dragObject.position.addVectors(dragPlaneIntersect, shift);
        dragObject.position.y = tmp.y;
    }
}

class PlaneDragXY extends Drag {
    move(dragObject, dragPlaneIntersect, shift, dragPlaneIntersectY, shiftY){
        const tmp = JSON.parse(JSON.stringify(dragObject.position));
        dragObject.position.addVectors(dragPlaneIntersectY, shiftY);
        dragObject.position.z = tmp.z;
    }
}

class GridDrag extends Drag {
    move(dragObject, dragPlaneIntersect, shift){
        dragObject.position.addVectors(dragPlaneIntersect, shift);
        dragObject.position.x = Math.ceil(dragObject.position.x/100)*100;
        dragObject.position.y = Math.ceil(dragObject.position.y/100)*100;
        dragObject.position.z = Math.ceil(dragObject.position.z/100)*100;
    }
}

const Dragging = {
    [dragModes.FREE_DRAG_XY] : PlaneDragXY,
    [dragModes.FREE_DRAG_XZ] : PlaneDragXZ,
    [dragModes.GRID_DRAG] : GridDrag,
    [dragModes.ALONG_X] : LinearX,
    [dragModes.ALONG_Y] : LinearY,
    [dragModes.ALONG_Z] : LinearZ,
    "default": Drag,
};

export default Dragging;


