import { createFunction } from "../../dev/helpers";

type TExpression = boolean | number | string;

interface IContext {
    [key: string]: TExpression
}

export interface IExpressionMap {
    [key: string]: TExpression
}

export function evaluateExpression(expression: TExpression, context: IContext): TExpression {
    if (expression === undefined) return undefined;
    return createFunction(expression)(context);
}

export function evaluateExpressionList(expressionList: TExpression[], context: IContext): TExpression[] {
    return expressionList.map(
        (expression) => evaluateExpression(expression, context),
    );
}

export function evaluateExpressionMap(expressionMap: IExpressionMap, context): IExpressionMap {
    const map = {};
    for (const key in expressionMap) {
        const expression = expressionMap[key];
        map[key] = evaluateExpression(expression, context);
    }
    return map;
}

export function stackTransformations(previous = {}, next = {}) {
    const transformation = {};
    for (const key in previous) {
        transformation[key] = (transformation[key] || 0) + (previous[key] || 0);
    }
    for (const key in next) {
        transformation[key] = (transformation[key] || 0) + (next[key] || 0);
    }
    return transformation;
}
