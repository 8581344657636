import * as R from "ramda";

import ComponentLibrary from "./ComponentLibrary";
import * as helpers from "./helpers";
import * as RenderComponents from "./RenderComponents";

/**
 * Get component
 * @param {string} type
 * @return {VirtualComponent | RenderComponent | undefined}
 */
export function getComponent(type) {
    if (type[0] === type[0].toLowerCase()) {
        return RenderComponents[type];
    }
    return ComponentLibrary.get(type);
}

/**
 * @typedef {Object} VirtualElement
 * @property {string} name
 * @property {Array.<VirtualElement | RenderElement>} children
 */

/**
 * Create virtual component
 * @param {string} name
 * @param {Object.<string, string | number>} locals
 * @param {Array.<Object>} children
 * @returns {VirtualComponent}
 */
export function createComponent(name, locals, ...children) {
    /**
     * Create virtual element
     * @typedef {function}
     * @function VirtualComponent
     * @param {Object.<string, string | number>} parameters
     * @param {Object.<string, number>} translation
     * @param {Object.<string, number>} rotation
     * @param {string[]} key
     * @returns {VirtualElement}
     */
    return function VirtualComponent(parameters, translation, rotation, key) {
        const context = {
            ...parameters,
            ...helpers.excuteDeclarationsWithContext(locals, parameters),
        };
        return {
            name,
            children: children
                .map((child) => {
                    if (child.iterators) {
                        return helpers.expandIterators(child.iterators, context).map((iteration) => {
                            return {
                                child,
                                childContext: {
                                    ...context,
                                    ...iteration,
                                },
                            };
                        });
                    }
                    return {
                        childContext: context,
                        child,
                    };
                })
                .flat()
                .map(({ child, childContext }, i) => {
                    if (child.disable) {
                        const disable = child.disable.some((disableCondition) => helpers.createFunction(disableCondition)(childContext));
                        if (disable) return undefined;
                    }
                    const Component = getComponent(child.type);
                    if (!Component) {
                        console.log("unknown", child.type);
                        return undefined;
                    }
                    return Component(
                        R.map((formula) => helpers.createFunction(formula)(childContext), child.parameters),
                        {
                            x: translation.x + helpers.createFunction(child.translation?.x || 0)(childContext),
                            y: translation.y + helpers.createFunction(child.translation?.y || 0)(childContext),
                            z: translation.z + helpers.createFunction(child.translation?.z || 0)(childContext),
                        },
                        {
                            x: rotation.x + helpers.createFunction(child.rotation?.x || 0)(childContext),
                            y: rotation.y + helpers.createFunction(child.rotation?.y || 0)(childContext),
                            z: rotation.z + helpers.createFunction(child.rotation?.z || 0)(childContext),
                        },
                        [
                            ...(Array.isArray(key) ? key : [key]),
                            name,
                            i,
                        ]
                    );
                })
                .filter((x) => x),
        };
    }
}
