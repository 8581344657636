import * as THREE from "three";

class Highlight {

    constructor(color) {
        this.color = color;
    }
}

const CLICK = new THREE.MeshLambertMaterial(
    { emissive: 0x3a3a3a,
        color: "#ffe1a8"});

const SELECTED = new THREE.MeshLambertMaterial(
    { emissive: 0x3a3a3a,
        color: "#91d9cd"});

const UNSELECTED = new THREE.MeshLambertMaterial(
    { emissive: 0x3a3a3a,
        color: "#aed1cb"});


export default class Selection {
    constructor(selected, highlight, models) {
        this.selected = selected;
        this.highlight = highlight;
        this.models= models;
    }

    setIntersects(intersects, that){
        const int = intersects;
        that.selected = int.map((i) => i.object.name)[0] || [];

        that.currentIntersects = int;
    }

    setSelected(intersects, that){
        if(intersects.length && intersects.length > 0){
            const { name } = intersects[0].object;
            this.selected = name;
        } else {
            this.selected = null;
        }
        that.config.other.selection.selected = this.selected;
        that.notify();
    }

    select(m, s = SELECTED){
        m.material = s;
    }

    unselect(m){
        m.material = UNSELECTED;
    }

    click(that){
            // clicking
            that.clicked = that.selected || null

    }

    selectCurrent(that, selected = that.selected){
        that.getAllMeshes().forEach(
            m => {
                selected.includes(m.name) ? this.select(m) : this.unselect(m);
                if(that.clicked && that.clicked === m.name) this.select(m, CLICK);
            }
        );
        // that.models.forEach(m => {
        //     selected === m.id ? m.select(this.highlight.value) : m.unselect()
        // } );
    }

}
