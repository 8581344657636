import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

import queries from "../queries";
import { Catalogue, Mdl } from "../styles";

export default function ModelCatalogue(props) {
    const { pickModel, toggleRefresh } = props;

    useEffect(
        () => {
            if (data) {
                refetch().then(
                    ({ data }) => {
                        if (!picked) return;
                        const refreshedModel = data.getAllModels.find(x => x.name === picked);
                        if (refreshedModel) pickModel(refreshedModel);
                    }
                );
            }
        },
        [toggleRefresh],
    );

    const [models, setModels] = useState([]);

    const [after, setAfter] = useState((new URLSearchParams(window.location.search)).get("after"));
    const [before, setBefore] = useState((new URLSearchParams(window.location.search)).get("before"));

    const [picked, setPicked] = useState((new URLSearchParams(window.location.search)).get("picked"));

    useEffect(
        () => {
            const searchParams = new URLSearchParams(window.location.search);

            if (after) searchParams.set("after", after);
            else searchParams.delete("after");

            if (before) searchParams.set("before", before);
            else searchParams.delete("before");

            if (picked) searchParams.set("picked", picked);
            else searchParams.delete("picked");

            const newURLState = window.location.pathname + "?" + searchParams.toString();
            history.pushState(null, "", newURLState);
        },
        [after, before, picked],
    );

    const { data, refetch } = useQuery(
        queries.GET_MODELS,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "cache-and-network",
            variables: {
                limit: 30,
                before,
                after,
            },
        },
    );

    const [getM, { data: pickedModel }] = useLazyQuery(
        queries.GET_MODEL_MINIMAL_DEFINITION,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "cache-and-network",
            variables: {
                name: picked,
                tag: "latest",
            },
        },
    );

    useEffect(
        getM,
        [picked],
    );

    useEffect(
        () => {
            if (!pickedModel) return;

            const { modelDefinition } = pickedModel;
            pickModel(
                {
                    name: picked,
                    nodes: [
                        {
                            _id: modelDefinition._id,
                            tags: ["latest"],
                        }
                    ],
                }
            );
        },
        [pickedModel],
    );

    useEffect(
        () => {
            if (data) {
                setModels(data?.getAllModels || []);
            }
        },
        [data],
    );

    return (
        <Catalogue>
            {
                models.map(
                    ({ name }) => (
                        <Mdl
                            key={ name }
                            onClick={ () => setPicked(name) }
                        >
                            { name }
                        </Mdl>
                    )
                )
            }
            <div className="paginate">
                <button
                    type="button"
                    onClick={
                        () => {
                            setAfter(undefined);
                            setBefore(models[0].name);
                        }
                    }
                >
                    previous
                </button>
                <button
                    type="button"
                    onClick={
                        () => {
                            setAfter(models.slice(-1)[0].name);
                            setBefore(undefined);
                        }
                    }
                >
                    next
                </button>
            </div>
        </Catalogue>
    );
}

