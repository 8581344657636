import React, { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import ModelCatalogue from "./components/ModelCatalogue";
import ModelCard from "./components/ModelCard";
import UserEditor from "./components/UserEditor";
import c from "./empty-scene.json"
import Ifr from "./Ifr";
import WC from "./WC";
import queries from "./queries";
import * as S from "./styles";


export default function Admin() {
    const sceneConfig = useRef(c);

    const [toggleRefresh, setToggleRefresh] = useState(false);
    const [toggleUpdate, setToggle] = useState(false);
    const [modelInfo, setModelInfo] = useState(null);

    const [saveGeometry, { data: savedData }] = useMutation(queries.SAVE_GEOMETRIES);

    const { data: objectTemplateRevisions, refetch } = useQuery(
        queries.QueryObjectTemplateRevisions,
        {
            skip: !modelInfo?.name,
            variables: {
                name: modelInfo?.name,
            },
        },
    );

    const [nodes, setNodes] = useState([]);

    useEffect(
        () => {
            setNodes(
                (objectTemplateRevisions?.objectTemplateRevisions?.[0]?._nodes1 ?? []).slice(0, 10)
            );
        },
        [objectTemplateRevisions],
    );

    useEffect(
        () => {
            refetch();
        },
        [toggleRefresh],
    );

    useEffect(
        () => {
            console.log("savedData", { savedData });
        },
        [savedData],
    );

    const params = new URLSearchParams(window.location.search);

    const updScene = (sc) => {
        sceneConfig.current = sc;
        setToggle(!toggleUpdate);
    }

    function save({ SCENE, ...parts }) {
        const sure = confirm("You sure?");
        if (!sure) return;
        const key = Object.keys(parts)[0];
        const g = { name: key, definition: parts[key] }
        saveGeometry(
            {
                variables: {
                    geometries: Object
                        .keys(parts)
                        .map(
                            (name) => {
                                const { locals, ...def } = parts[name];
                                return {
                                    name,
                                    definition: def,
                                    defaultParams: locals,
                                };
                            },
                        ),
                },
            },
        ).then(() => setToggleRefresh(!toggleRefresh))
    }

    return (
        <S.Main>
            <div>
                <button
                    onClick={ () => setModelInfo(null) }
                >
                    new
                </button>
                <ModelCatalogue
                    toggleRefresh={ toggleRefresh }
                    pickModel={ ({ name, ...p }) => setModelInfo({ name, ...p }) }
                />
            </div>
            <S.Edit>
                {
                    modelInfo
                        ? (
                            <ModelCard
                                toggleRefresh={ toggleRefresh }
                                show
                                sceneConfig={ sceneConfig.current }
                                setSceneConfig={ updScene }
                                save={ save }
                                doRefresh={ () => setToggleRefresh(!toggleRefresh) }
                                { ...modelInfo }
                                nodes={ nodes }
                            />
                        )
                        : (
                            <UserEditor
                                show
                                sceneConfig={ sceneConfig.current }
                                setSceneConfig={ updScene }
                                save={ save }
                            />
                        )
                }
            </S.Edit>
            {
                ["old"].includes(params.get("engine")) && (
                    <Ifr
                        config={ sceneConfig.current }
                    />
                )
            }
            <WC
                config={ sceneConfig.current }
                modelInfo={ modelInfo }
            />
        </S.Main>
    )
}


function makeRenderable({ SCENE, ...parts }) {
    return SCENE.map((model) => {
        if (typeof model === "string") return { name: model };
        const name = Object.keys(model)[0];
        return {
            name, ...model[name]
        }
    }).map((x) => ({ ...x, dev: parts }))
};
