import * as THREE from "three";

import TemplateInterpreter from "../core/TemplateInterpreter";
import AbstractObjectBuilder from "../core/AbstractObjectBuilder";
import AbstractObjectFactory from "../core/AbstractObjectFactory";

export default class GroupInterpreter extends TemplateInterpreter {

    static async * interpretChildren(template, context, pt, Library, interpret) {
        for (const child of template.children) {
            yield interpret(child, context, pt, Library, template.children.indexOf(child));
        }
    }

    static async interpretSpecification(template, context, interpret, Library) {
        const children = [];
        const generator = this.interpretChildren(template, context, undefined, Library, interpret);
        for await (const child of generator) {
            children.push(child);
        }
        return {
            name: template.name,
            children,
        };
    }

    static async interpret(template, context, pt, Library, interpret, key) {
        const specification = await this.interpretSpecification(template, context, interpret, Library);
        const transformations = this.interpretTransformations(template, context, pt);
        return new GroupFactory(specification, transformations, key);
    }

}

class GroupFactory extends AbstractObjectFactory {

    constructor(readonly s, readonly t, readonly k) {
        super();
    }

    * generate(container) {
        const group = new GroupBuilder(this.s, this.t, this.k);
        yield {
            object: group,
            container,
        };
        for (const child of this.s.children) {
            yield {
                object: child,
                container: group.object,
            };
        }
    }

}

export class GroupBuilder extends AbstractObjectBuilder {

    constructor(
        readonly specification,
        readonly transformations,
        readonly key,
    ) {
        super();
    }

    findHost(root) {
        return root.children.find((child) => child.userData.key === this.key);
    }

    construct() {
        // console.log("create group");
        const group = new THREE.Group();

        const { name } = this.specification;
        group.name = name;

        // region userData
        group.userData.key = this.key;
        // endregion

        return group;
    }

    apply(object) {
        // console.log("apply group", this.key);
    }

}
