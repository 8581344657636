import * as THREE from "three";

import AbstractObjectBuilder from "../core/AbstractObjectBuilder";
import TemplateInterpreter from "../core/TemplateInterpreter";
import AbstractObjectFactory from "../core/AbstractObjectFactory";
import * as evaluation from "../helpers/evaluation";

export default class AxisCrossInterpreter extends TemplateInterpreter {

    static async interpret(template, context, previTrans, Library, interpret, key) {
        const bContext = {
            ...context,
            _AXIS_CROSS: true,
        };
        const size = evaluation.evaluateExpression(template.axisCross, context);
        const builder = await interpret(template, bContext, previTrans, Library, key);
        return new AxisCrossFactory(builder, size);
    }

}

class AxisCrossFactory extends AbstractObjectFactory {

    constructor(readonly builder, readonly size) {
        super();
    }

    * generate(container) {
        yield {
            object: this.builder,
            container,
        };
        if (this.builder instanceof AbstractObjectFactory) {
            const bs = Array.from(this.overBS());
            for (const b of bs) {
                yield {
                    object: new AxisCrossBuilder(this.size),
                    container: (b as AbstractObjectBuilder).object,
                };
            }
        }
    }

}

class AxisCrossBuilder extends AbstractObjectBuilder {

    constructor(readonly size) {
        super();
    }

    construct() {
        return new THREE.AxesHelper(this.size);
    }

    findHost(container) {
        return undefined;
    }

    apply(object) {}

    applyTransformations(object) {}

}
