import React, { useState, useEffect } from "react"
import Editor from "./TextEditor";
import {useQuery} from "@apollo/client";
import queries from "../queries";
import {cloneDeep} from "@apollo/client/utilities";
import {TRIPLE_ZERO} from "../../engine/sandy/core/models/helpers";

export default function UserEditor({ value = "", show, save, sceneConfig, setSceneConfig}){

    useEffect(() => { setEditValue(value) }, [value])
    const [ editValue, setEditValue] = useState(value);
    const [ toBeLoaded, setToBeLoaded ] = useState([]);
    const [ renderInput, setRenderInput ] = useState(null);
    const { error, loading, data } = useQuery(queries.GET_SCENE, {
        variables: { scene: toBeLoaded },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {

        if(!loading){
            setSceneConfig(updateSceneConfig({
                sceneConfig,
                renderInput,
                data
            }))
        }

    }, [data, renderInput]);

    useEffect(() => {
        setEditValue(value);
    }, [ value ]);

    const changed = ({ SCENE, ...parts}) => {
        setRenderInput({ SCENE, ...parts});
        setToBeLoaded(() => {
            const arr = [];
            for( const p of Object.keys(parts) ) {
                if(parts[p].children){
                    parts[p].children.forEach(ch => {
                        if(!parts[ch]) arr.push({name:  typeof ch === "string" ? ch : Object.keys(ch)[0]});
                    })
                }
            }
            for( const p of SCENE ) {
                const name = typeof p === "string"? p : Object.keys(p)[0];
                arr.push({ name });
            }
            return arr.filter(({ name }) => !Object.keys(parts).includes(name));
        })
    }

    return ( show ? <Editor
        changed={ changed }
        save={save}
        value={editValue}
        setValue={setEditValue}
    /> : null)
};

export function updateSceneConfig( { sceneConfig, renderInput, data = { sceneDefinition: {} } } ){

    if(!sceneConfig || !renderInput || !data) return sceneConfig;
    const { SCENE, ...parts } = renderInput;


    const updatedScene = cloneDeep(sceneConfig);

    const globalDev = Object.keys(data.sceneDefinition).reduce((o, key) => ({
        ...o,
        ...Object.keys(data.sceneDefinition[key].definition)
            .reduce((xo, xkey) => ({...xo, [xkey]: {
                ...data.sceneDefinition[key].definition[xkey].definition,
                locals: data.sceneDefinition[key].defaultParams } }), {})
    }), {});


    const mdls = SCENE.map(
        (o) => {

            let name;
            let obj;
            if(typeof o === "string"){
                name = o;
                obj = {}
            } else {
                name = Object.keys(o)[0];
                obj = o[name];
            }

            const loaded = data?.sceneDefinition[name] || {};

            const yamlConfigurationAvailable = loaded.definition ? Object.keys(loaded.definition).reduce(
                (o, key) => ({...o,
                    [key]: loaded.definition[key].definition
                }), {}
            ) : {};


            const dev = (() => {
                if(Object.keys(yamlConfigurationAvailable).length > 0) return yamlConfigurationAvailable;

                const d = parts[name] || {};
                let dev = { [name]: d };
                if(d.children) {
                    dev = {
                       ...globalDev, ...dev
                    };
                }
                return dev;
            })()

           return {
                ...obj,
                object: name,
                type: "dev",
                dev,
                params: { ...loaded.defaultParams, ...obj.params || {} },
                translate: {...TRIPLE_ZERO, ...obj.translate || {}}
            }}

    )

    updatedScene.scenes.scene.models = mdls.reduce(
        (o, mdl, i) => ({...o,
            [`${mdl.object}${i}`] : mdl
        }), {}
    );

    updatedScene.scenes.scene.removeModels =
        Object.keys(sceneConfig.scenes.scene.models)
            .filter((m) => !Object.keys(updatedScene.scenes.scene.models).includes(m));

    return updatedScene;
}
