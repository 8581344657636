import styled from "@emotion/styled";
import React, { useEffect } from "react";

import RenderDispatcher from "./RenderDispatcher";
import Sandy from "./sandy";

const StyledDiv = styled.div`
    width: 100vw;
    height: 100vh;
`;

const dispatch  = {
    init(c) {
        RenderDispatcher.schedule(
            () => {
                Sandy.init(c);
                Sandy.setNotification((conf) => port2.postMessage({ conf }));
            },
        );
    },
    change(c) {
        RenderDispatcher.schedule(
            () => Sandy.change(c),
            "change",
        )
    },
    reset(c) {
        RenderDispatcher.schedule(
            () => Sandy.resetConfig(c),
        );
    },
    shot() {
        RenderDispatcher.schedule(
            () => {
                window.currentShot = null;
                window.waitingForShot = true;
                setTimeout(
                    () => {
                        if (window.currentShot) {
                            port2.postMessage({ shot: window.currentShot });
                        }
                    },
                    100,
                );
            },
        );
    }
}

let port2 = null;

export function onMessage (message) {
    // console.log("engine-3d.blank.onMessage");
    // console.log(message);
    const { data } = message;
    const { action, config } = data;
    dispatch[action](config);
}

function initPort(e) {
    // console.log("engine-3d.blank.initPort", e);
    port2 = e.ports[0];
    port2.onmessage = onMessage;
}

export function setPort(port) {
    port2 = port;
}

export default function UI(){
    useEffect(
        () => {
            window.addEventListener("message", initPort);
            window.onmessage = initPort;
        },
        [],
    );

    return <StyledDiv id="sandy"/>;
}
