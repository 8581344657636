import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @font-face {
        font-family: "Nudista Light";
        src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-light.otf");
    }

    @font-face {
        font-family: "Nudista Medium";
        src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-medium.otf");
    }

    @font-face {
        font-family: "Nudista Bold";
        src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-bold.otf");
    }

    html, body, #root {
        height: 100%;
    }

    body {
        font-family: "Nudista Medium", sans-serif;
        margin: 0;
    }
`;
