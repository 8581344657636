export const GroupWithChildWithOneIterator = {
    kind: "Group",
    children: [
        {
            kind: "Primitive",
            primitive: "Cuboid",
            geometry: {
                depth: 200,
                height: 200,
                width: 200,
            },
            material: {
                color: "'#789abc'",
                opacity: .9,
            },
        },
        {
            kind: "Primitive",
            primitive: "Cuboid",
            geometry: {
                depth: 100,
                height: 100,
                width: 100,
            },
            material: {
                color: "'#654321'",
                opacity: .5,
            },
            translation: {
                x: 140,
                y: "i * (100 + 10)",
            },
            iterators: {
                i: {
                    start: 0,
                    step: 1,
                    stop: 10,
                },
            },
        },
    ],
};
