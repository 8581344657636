export default class RenderDispatcher {

    static queue = [];

    static async schedule(executor, skippableType = false) {
        this.queue.push({ executor, skippableType });
        this.start();
    }

    static start() {
        if (this.queue.length > 0) {
            this.next();
        }
    }

    static async next() {
        await new Promise((resolve) => setTimeout(resolve, 1));

        if (this.queue.length === 0) {
            return;
        }

        const { executor, skippableType } = this.queue.shift();

        if (skippableType) {
            const queueOfType = this.queue.filter((task) => task.skippableType === skippableType);
            if (queueOfType.length > 0) {
                return this.next();
            }
        }

        try {
            executor();
        } catch (e) {
            console.log(e);
        }

        return this.next();
    }

}
