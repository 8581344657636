import fence from "./fence.json"
import fence_foundation from "./fence_foundation.json"

import blinds from "./blinds.json"
import tiles from "./tiles.json"

export default {
    "MasonryFence": fence,
    "MasonryFenceFoundation": fence_foundation,
    "Blinds": blinds,
    "Tiles": tiles,
}
