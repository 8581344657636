export const SELECTOR_DOM = "#sandy";
export const DEFAULT_MESH_COLOR = "#c9c9c9";

// drag modes

export const dragModes = {
 FREE_DRAG_XY: "free drag XZ",
 FREE_DRAG_XZ: "free drag XY",
 GRID_DRAG: "grid",
 ALONG_X: "along X",
 ALONG_Y: "along Y",
 ALONG_Z: "along Z",
}

// orbit controls

export const NEAR = 1;
export const FAR = 10000000;

export const LOOKAT_DISTANCE = 3000;

// geometries
export const radialSegments = 20;
