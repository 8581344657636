import React, { useState, useEffect } from "react";
import YAML from "yaml";

import * as S from "../styles";
import { useMutation, useQuery } from "@apollo/client";
import queries from "../queries";
import UserEditor from "./UserEditor";
import C from "../../../constants";

function ButtonEdit({ edit, name }) {
    return (
        <button
            onClick={ () => edit({ name }) }
        >
            edit
        </button>
    );
}

export default function Model(props) {
    const {
        show,
        save,
        doRefresh,
        name,
        nodes,
        sceneConfig,
        setSceneConfig
    } = props;

    const [editValue, setEditValue] = useState("");
    const [editId, setEditId] = useState(null);
    const [tmpTag, setTmpTag] = useState(C.DEFAULT_TAG);

    useEffect(
        () => {
            setTmpTag(C.DEFAULT_TAG)
        },
        [editId],
    );

    const { loading, error, data } = useQuery(
        queries.GET_MODEL_MINIMAL_DEFINITION,
        {
            variables: {
                modelID: editId,
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    const [saveTag] = useMutation(queries.ASSIGN_TAG);

    useEffect(
        () => {
            if (!loading) {
                const { name, definition, defaultParams } = data.modelDefinition;
                const json = {}
                json[name] = defaultParams ? { locals: defaultParams, ...definition } : definition;
                json.SCENE = [name];
                setEditValue(YAML.stringify(json));
            }
        },
        [data]
    );

    useEffect(
        () => {
            if (nodes && nodes[0]?._id) {
                setEditId(nodes[0]._id)
            }
        },
        [nodes]
    );

    if (!show) {
        return null;
    }

    return (
        <>
            <UserEditor
                value={ editValue }
                show={ true }
                save={ save }
                sceneConfig={ sceneConfig }
                setSceneConfig={ setSceneConfig }
            />
            <div style={ { margin: "20px 0" } }>
                <S.ModelCard>
                    <div className="name">
                        <div>{ name }</div>
                    </div>
                    <table>
                        <tr>
                            <th>id</th>
                            <th>tag</th>
                            <th>action</th>
                        </tr>
                        {
                            nodes.map(
                                ({ _id, tags }) => (
                                    <tr className={ _id === editId ? "highlight" : "" } key={ _id }>
                                        <td>{ _id }</td>
                                        <td>{ tags && tags.map((t) => <div>{ t }</div>) }</td>
                                        <td>
                                            <ButtonEdit edit={ () => setEditId(_id) } />
                                        </td>
                                    </tr>
                                )
                            )
                        }
                    </table>
                </S.ModelCard>
                <label
                    htmlFor="addtag"
                >
                    Add tag:
                </label>
                <input
                    type="text"
                    id="addtag"
                    name="addtag"
                    value={ tmpTag }
                    onChange={ (e) => setTmpTag(e.target.value) }
                />
                <button
                    onClick={
                        () => {
                            const sure = confirm("Are you sure?");
                            if (sure) {
                                saveTag(
                                    {
                                        variables: {
                                            modelID: editId,
                                            tag: tmpTag,
                                        },
                                    },
                                ).then(doRefresh);
                            }
                        }
                    }
                >
                    Add tag
                </button>
            </div>
        </>
    );
}
