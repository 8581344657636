import * as Primitive from "./Primitive";
export * from "./Primitive";
import * as Reference from "./Reference";
export * from "./Reference";
import * as Group from "./Group";
export * from "./Group";
import * as File from "./File";
export * from "./File";

const exampleMap = {
    ...Primitive,
    ...Reference,
    ...Group,
    ...File,
};

Object
    .keys(exampleMap)
    .forEach(
        (key) => {
            const example = exampleMap[key];
            example.name = key;
        },
    );
