import * as THREE from "three";
import * as Models from "./model-types";
import * as CONSTANTS from "../constants";

export const TRIPLE_ZERO = { x: 0, y: 0, z: 0};

export function hideMesh(mesh){
    mesh.scale.x = 0;
    mesh.scale.y = 0;
    mesh.scale.z = 0;
}

// origin enum: Center, BaseCenter, Nil

export function translateBox({
    origin = 'Nil',
                          translation = { x: 0, y: 0, z: 0},
                          size = { width: 0, height: 0, depth: 0},
                            target
}){

    const { width, height, depth } = size;
    const { x, y, z } = { ...TRIPLE_ZERO, ...translation };
    target.x = (origin === "Nil" ? 0 : (-.5 * width)) + x * (origin === "Nil" ? 1 : -1);
    target.y = (origin === "Nil" ? 0 : (.5 * height)) + z;
    target.z = (origin === "Nil" ? 0 : (-.5 * depth)) + y * (origin === "Nil" ? 1 : -1);

}

export function makeModel(id, obj){
    if(!obj.hasOwnProperty("type")) return null;
    return Models[obj?.type](id, obj) || null;
}

export function interpretRotationXYZ(rot){
    return {
        x: rot && rot.x ? interpretRotation(rot.x) : 0,
        y: rot && rot.y ? interpretRotation(rot.y) : 0,
        z: rot && rot.z ? interpretRotation(rot.z) : 0
    }
}

export function interpretRotation(rot) {
    const deg = parseInt(rot, 10);
    return deg * (Math.PI / 180);
}

function makeDefaultMaterial(color = CONSTANTS.DEFAULT_MESH_COLOR) {
    return new THREE.MeshLambertMaterial(
        { emissive: 0x3a3a3a,
            color: /^#[0-9A-F]{6}$/i.test(color) ? color : CONSTANTS.DEFAULT_MESH_COLOR} );
}

export function makeBox({
                            name = "box",
                                width = 1,
                                height = 1,
                                depth = 1,
                            color = "#141414",
                            translate,
                            rotation,
    origin = "Nil",
    bevelEdge,
                        }){

    let geometry = new THREE.BoxGeometry( 1,1,1);
    if (bevelEdge) {
        const vertexCount = geometry.attributes.position.array.length / 3;
        for (let i = 0; i < vertexCount; i += 1) {
            const x = geometry.attributes.position.array[i * 3];
            const y = geometry.attributes.position.array[i * 3 + 1];
            const z = geometry.attributes.position.array[i * 3 + 2];
            if (x === .5 && y === .5) {
                geometry.attributes.position.array[i * 3 + 1] = 1 - width / height - .5;
            }
        }
    }
    if (origin === "Nil") {
        geometry.translate(.5, .5, .5);
    }
    if (origin === "BaseCenter") {
        geometry.translate(0, .5, 0);
    }
    const material = makeDefaultMaterial(color);
    const mesh = new THREE.Mesh( geometry, material );
    mesh.scale.x = width;
    mesh.scale.y = height;
    mesh.scale.z = depth;
    mesh.name = name ;
    if (translate) {
       translateBox({ translation: translate, size: { width, depth, height}, target: mesh.position });
    }

    if (rotation) {
        mesh.rotation.x = interpretRotation(rotation.x);
        mesh.rotation.z = interpretRotation(rotation.y);
        mesh.rotation.y = interpretRotation(rotation.z);
    }

    return { geometry, materials: [material], mesh };
}

export function makeCylinder(
    {
        name = "cylinder",
        radius = 200,
        height = 200,
        color,
        translate,
        rotation
    }
){

    const geometry = new THREE.CylinderGeometry( radius,radius,height, CONSTANTS.radialSegments );
    const material = makeDefaultMaterial(color);
    const mesh = new THREE.Mesh( geometry, material );

    mesh.name = name ;

    if (translate) {
        translateBox({
            translation: translate,
            size: { width: radius * 2, depth: radius * 2, height },
            target: mesh.position }
            );
    }

    return { geometry, materials: [material], mesh };

}



export function makeCone({
                             name = "cone",
                             radius = 200,
                             height = 200,
                             color = "#141414",
                             translate,
                             radialSegments = 4,
                             rotation
                         }){

    const geometry = new THREE.ConeGeometry(radius, height, radialSegments);
    const material = makeDefaultMaterial(color);
    const mesh = new THREE.Mesh( geometry, material );

    mesh.name = name;

    if (translate) {
        translateBox({
            translation: translate,
            size: { width: radius * 2, depth: radius * 2, height },
            target: mesh.position }
        );
    }

    return { geometry, materials: [material], mesh };
}
