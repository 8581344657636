import useTemplateLibrary from "./templateLibrary/useTemplateLibrary";
import useFBXLoader from "./useFBXLoader";
import parse from "./templateLibrary/parse";

export default function useLibrary() {
    const loadTemplate = useTemplateLibrary();
    const loadFBX = useFBXLoader();

    function withLocalTemplates(localTemplates = {}) {
        const localLibrary = {};
        Object
            .keys(localTemplates)
            .map((key) => [key, localTemplates[key]])
            .map(([templateName, template]) => parse(templateName, template))
            .forEach((template) => localLibrary[template.name] = template)
        function loadTemplate2(templateName) {
            if (localLibrary[templateName]) {
                return localLibrary[templateName];
            }
            return loadTemplate(templateName);
        }

        return {
            loadTemplate: loadTemplate2,
            loadFBX,
        };
    }

    return {
        withLocalTemplates,
        loadTemplate,
        loadFBX,
        // loadTexture: load,
        // loadScene: load,
    };
}
