import * as THREE from "three";
import { makeModel } from "../models/helpers";
import * as C from "../constants";
import * as CHANGE from './change';
import * as CUSTOMIZE from './customize';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";


export default class Scene {

    id;
    models = [];
    // DOM element
    mount;

    // callback interaction
    notificationsON = false;
    notify = () => {};

    // render objects
    scene = new THREE.Scene();
    camera = null;
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha : true });

    //raycasting
    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // drag
    dragging = null;
    currentIntersects = [];
    dragObject = null;

    // select
    selection = null;
    selected = []

    constructor({ id, models, other }) {
        this.id = id;
        this.config = { id, models, other };
        if(models) {
            this.models = Object.entries(models).map((m) => makeModel(...m));
        }
        if(other) {
            this.sceneConfig = other;
        }
       this.init();
    }

    init() {
        this.mount = window.document.querySelector(C.SELECTOR_DOM);

        this.mount.addEventListener("pointermove", this.onMouseMove.bind(this));
        this.mount.addEventListener("pointerdown", this.onMouseDown.bind(this));
        this.mount.addEventListener("pointerup", this.onMouseUp.bind(this));

        let width = this.mount.clientWidth;
        let height = this.mount.clientHeight;

        this.camera = new THREE.PerspectiveCamera(45, width / height, C.NEAR, C.FAR);

        this.orbitControls = new OrbitControls( this.camera, this.renderer.domElement );

        this.orbitControls.enableDamping = true;
        this.orbitControls.dampingFactor  = 0.35;
        this.orbitControls.minDistance = C.NEAR * 1000;
        this.orbitControls.maxDistance = C.FAR / 500;

        this.camera.position.z = 5000;
        this.camera.position.y = 100;
        this.orbitControls.update();
        const ambLight = new THREE.AmbientLight( 0x404040, .9 );
        this.scene.add(ambLight);
        const light1 = new THREE.PointLight( 0xffffff, 0.55, 10000 );
        light1.position.set( 0, 850, 5350 );
        this.scene.add( light1 );
        const light2 = new THREE.PointLight( 0xffffff, 0.55, 10000 );
        light2.position.set( 0, 850, -5350 );
        this.scene.add( light2 );
       const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x5e5e5e, .6 );
        this.scene.add( hemiLight );
        this.models.forEach(
            (mesh) => {
                mesh.addTo(this.scene)
            }
        );


        for(const [property, val] of Object.entries(this.sceneConfig)){
            CUSTOMIZE[property](val, this);
        }

        this.renderer.setClearColor('#000000', 0)
        this.renderer.setSize(width, height)
        this.mount.appendChild(this.renderer.domElement);
        this.start();

    }

    getAllMeshes(){
        return [...this.scene.children.filter(({ type }) => type === "Mesh"),
            ...this.scene.children.filter(({ type }) => type === "Group")
                .map((gr) => gr.children.filter(({ type }) => type === "Mesh"))].flat();
    }

    setNotification(notify){
        this.notificationsON = true;
        this.notify = () => {
            if(this.notificationsON) notify(this.getConfig)
        };
        this.notify();
    }

    getConfig(){
        return {[this.id] : {
                ...this.config,
                models : this.models.reduce((o, m) => ({ ...o, [m.id] : m.getConfig()}), {})
            }};
    }

    applyChange(diff){
        for(const [key, changes] of Object.entries(diff)){
            CHANGE[key](changes, this);
        };
        this.notify();
    }

    makeGrid(){
        const grid = new THREE.GridHelper(100, 100);
        grid.position.y = -0.5;
        this.scene.add(grid);
    }

    doResize(width, height) {
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderScene();
    }

    animate() {
        this.frameId = window.requestAnimationFrame(this.animate.bind(this));
        this.orbitControls.update();
        this.renderScene();
    }

    start() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate.bind(this))
        }
    }

    stop() {
        cancelAnimationFrame(this.frameId)
        this.frameId = null
    }

    renderScene () {

        const { renderer, scene, camera, raycaster, mouse, selection } = this;

        if(selection) {
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(this.getAllMeshes());
            selection.setIntersects(intersects, this);
            selection.selectCurrent(this);
        }
        renderer.render(scene, camera)
        if(window.waitingForShot){
                window.currentShot = document.querySelector("canvas").toDataURL();
                window.waitingForShot = false;
        }
    }

    getDOMElement() {
        return this.renderer.domElement;
    }

    moveObject(name, position){
        const model = this.models.find(({ id }) => id === name);
        model.updateConfig();
        this.notify();
    }

    onMouseMove( event ) {
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = -1 * ( event.clientY / window.innerHeight ) * 2 + 1;

        if (this.dragging && this.dragging.active) {
            this.raycaster.ray.intersectPlane(this.dragPlane, this.dragPlaneIntersect);
            this.raycaster.ray.intersectPlane(this.dragPlaneY, this.dragPlaneIntersectY);
            this.dragging.move(this.dragObject, this.dragPlaneIntersect, this.shift, this.dragPlaneIntersectY, this.shiftY);
        }

    }

    onMouseDown(){
        const intersects = this.currentIntersects;
        if(this.selection){
            this.selection.click(this);
        }
        if (this.dragging && intersects.length && intersects.length > 0) {
            this.dragging.active = true;
            this.orbitControls.enabled = false;
            this.orbitControls.enableRotate = false;
            this.orbitControls.saveState();

            this.dragPIntersect.copy(intersects[0].point);
            this.dragPlane.setFromNormalAndCoplanarPoint(this.dragPNormal, this.dragPIntersect);
            this.shift.subVectors(intersects[0].object.position, intersects[0].point);

            this.dragPIntersectY.copy(intersects[0].point);
            this.dragPlaneY.setFromNormalAndCoplanarPoint(this.dragPNormalY, this.dragPIntersectY);
            this.shiftY.subVectors(intersects[0].object.position, intersects[0].point);

            this.dragObject = intersects[0].object;
        }
    }

    onMouseUp(event) {

        const intersects = this.currentIntersects;

        if (this.selection){
            this.selection.setSelected(intersects || [], this);
        }

        if(this.dragging && this.dragging.active) {
            this.dragging.active = false;
            const { name, position } = this.dragObject;
            this.moveObject(name, position);
            this.dragObject = null;
            this.orbitControls.reset();
            this.orbitControls.enabled = true;
            this.orbitControls.enableRotate = true;
        }
    };

    finalize(){
        this.scene.remove.apply(this.scene, this.scene.children);
        this.stop();
        this.models.forEach(m => m.dispose());
        this.renderer.clear();
        this.mount.removeChild(this.renderer.domElement);

    }

}
