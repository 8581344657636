export function degToRad(deg: number): number {
    return deg * Math.PI / 180;
}

export const FEET_TO_M: number = 0.3048;

export const M_TO_MM: number = 1000;

export function feetToMillimeters(feet: number): number {
    return feet * FEET_TO_M * M_TO_MM;
}
