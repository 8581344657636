function parseDefaults(modelDefaults = {}) {
    const templateDefaults = {};
    for (const [key, value] of Object.entries(modelDefaults)) {
        templateDefaults[key] = typeof value === "string"
            ? value.replace(/'/g, "")
            : value;
    }
    return templateDefaults;
}

export default function parse(templateName, model) {
    // console.groupCollapsed("parse", templateName);
    // console.log("model", model);
    if (model.fileURL) {
        return {
            ...model,
            name: templateName,
            kind: "File",
        };
    }

    const template: ITemplate = {
        name: templateName,
        defaults: parseDefaults(model.defaults),
        locals: model.locals,
        disable: model.disable,
        translation: model.translation,
        rotation: model.rotation,
    };

    if (model.iterators) {
        template.iterators = {};
        for (const iteratorKey in model.iterators) {
            template.iterators[iteratorKey] = {
                start: model.iterators[iteratorKey].start,
                step: model.iterators[iteratorKey].step,
                stop: model.iterators[iteratorKey].end,
            };
        }
    }

    if (model.children) {
        template.kind = "Group";
        template.children = model
            .children
            .map((child) => [Object.keys(child)[0], Object.values(child)[0]])
            .map(([childKey, childDef]) => parse(childKey, childDef));
    }

    if (templateName === "cuboid") {
        template.kind = "Primitive";
        template.primitive = "Cuboid";
        template.geometry = {
            depth: model.parameters.z,
            height: model.parameters.y,
            width: model.parameters.x,
        };
        template.material = {
            color: model.parameters.color,
            opacity: model.parameters.opacity,
        };
        template.origin = model.parameters.origin || "'Nil'";
    }

    if (!template.kind) {
        template.kind = "Reference";
        template.template = templateName;
        template.parameters = model.parameters;
    }

    // console.log("template", template);
    // console.groupEnd();
    return template;
}
