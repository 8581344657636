export const Group = {
    kind: "Group",
    children: [
        {
            kind: "Primitive",
            primitive: "Cuboid",
            geometry: {
                depth: 200,
                height: 200,
                width: 200,
            },
            material: {
                color: "'#abcdef'",
            },
        },
        {
            kind: "Primitive",
            primitive: "Cuboid",
            geometry: {
                depth: 100,
                height: 100,
                width: 100,
            },
            material: {
                color: "'#654321'",
                opacity: .5,
            },
            translation: {
                x: 140,
            }
        },
    ],
};
