import React from "react";
import YAML from "yaml";

import { TextEditor } from "../styles";

export default function Editor({ value, setValue, changed, save }) {
    return (
        <TextEditor>
            <textarea
                id="noter-text-area"
                name="textarea"
                onChange={ (e) => setValue(e.target.value) }
                value={ value }
            />
            <div>
                <button
                    onClick={ () => receiveYAML(value, changed) }
                >
                    Render scene
                </button>
                <button
                    onClick={ () => receiveYAML(value, save) }
                >
                    Save Geometries
                </button>
            </div>
        </TextEditor>
    );
}

function receiveYAML(blob, callback) {
    let obj = {};
    try {
        obj = YAML.parse(blob);
    } catch (e) {
        console.error(e);
    }
    if (Object.keys(obj).length > 0) {
        callback(obj)
    }
}
