import { CartesianProduct } from "js-combinatorics";

import * as evaluation from "./evaluation";

interface IIterator extends evaluation.IExpressionMap {
    start: number
    step: number
    stop: number
}

function* expandIterator(key, iterator, context) {
    const { start, step, stop } = evaluation.evaluateExpressionMap(iterator, context) as IIterator;
    for (let n = start; n <= stop; n += step) {
        yield { [key]: n };
    }
}

export function* expandIterators(iterators, context) {
    const iterations = {};
    for (const key in iterators) {
        iterations[key] = Array.from(expandIterator(key, iterators[key], context));
    }
    const cartesianProduct = CartesianProduct.from(Object.values(iterations));
    for (const pair of cartesianProduct) {
        yield Object.assign({}, ...pair);
    }
}

