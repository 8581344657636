import React from "react";
import ReactDOM from "react-dom";

import GlobalStyle from "./GlobalStyle";
import pkg from "../../package.json";

import App from "./App"

console.log(`engine3d v${ pkg.version }`);

ReactDOM.render(
    <>
        <GlobalStyle />
        <App />
    </>,
    document.getElementById("root"),
);
