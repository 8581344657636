import { transform, isEqual, isObject } from 'lodash';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            if(key === "dev") {
                result[key] = value;
            } else {
                result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value
            }
        }
    });
}
