import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export function resizeRenderer(renderer) {
    const {
        clientHeight,
        clientWidth,
        height,
        width,
    } = renderer.domElement;

    const needResize = clientHeight !== height || clientWidth !== width;

    if (needResize) renderer.setSize(clientWidth, clientHeight, false);

    return needResize;
}

export function makeCamera(canvas) {
    return new THREE.PerspectiveCamera(
        80,
        canvas.clientWidth / canvas.clientHeight,
        1,
        500000,
    );
}

export function makeAmbientLight() {
    return new THREE.AmbientLight(
        0xffffff,
        1,
    );
}

export function makeOrbitControls(camera, canvas) {
    const orbitControls = new OrbitControls(camera, canvas);
    orbitControls.enableDamping = true;
    orbitControls.dampingFactor = .2;
    orbitControls.minDistance = 10;
    orbitControls.maxDistance = 100000;
    return orbitControls;
}
