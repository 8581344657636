
/**
 * @typedef {Object} RenderElement
 * @property {string} key
 * @property {string} object
 * @property {Object.<string, string | number>} parameters
 * @property {Object.<string, number>} translation
 * @property {Object.<string, number>} rotation
 */

/**
 * Create cuboid element
 * @typedef {function}
 * @function RenderComponent
 * @param {Object.<string, string | number>} parameters
 * @param {Object.<string, number>} translation
 * @param {Object.<string, number>} rotation
 * @param {string[]} key
 * @returns {RenderElement}
 */

function primivite (parameters, translation, rotation, key){
    const k = [...key, "cuboid"].join(":");
    const p = {
        width: parameters.x,
        height: parameters.height || parameters.z,
        depth: parameters.y,
        radius: parameters.radius || parameters.x / 2,
        color: parameters.material || parameters.color || "#ffffff",
        origin: parameters.origin,
        bevelEdge: parameters.bevelEdge,
        name: k,
        translation, rotation
    }
    return {
        key: k,
        parameters: p,
        translation,
        rotation,
        сolor: p.color,
    };
}

export function cuboid(parameters, translation, rotation, key) {
    return {...primivite(...arguments), object: "box"}
}

export function cone(parameters, translation, rotation, key) {
    return {...primivite(...arguments), object: "cone"}
}

export function cylinder(parameters, translation, rotation, key) {
    return {...primivite(...arguments), object: "cylinder"}
}

/**
 * Create plane element
 * @typedef {function}
 * @function RenderComponent
 * @param {Object.<string, string | number>} parameters
 * @param {Object.<string, number>} translation
 * @param {Object.<string, number>} rotation
 * @param {string[]} key
 * @returns {RenderElement}
 */
export function plane(parameters, translation, rotation, key) {
    return {...primivite(...arguments), object: "box"}
}
