import Model from "./model";
import {makeBox, makeCylinder, makeCone, hideMesh} from "../helpers";
import  { TextureLoader } from "three";
import * as THREE from "three";
import { RepeatWrapping } from "three";
import cloneDeep from "lodash.clonedeep";

const make = {
    "box": makeBox,
    "cylinder": makeCylinder,
    "cone": makeCone,
}

export default class PrimitiveModel extends Model {

    constructor(id, config) {
        super(id, config);

        const { object } = config;
        const { geometry: g,  materials: m,  mesh: me } = make[object]({ ...config, ...config.params, name: id});
        this.geometry = g;
        this.materials = m;
        this.mesh = me;
        if(config.texture && config.texture.url) {
            this.setTexture(config.texture)
        }

    }

    setTexture(texture){

        if(texture.preloaded){
            this.textures = texture.preloaded;
        } else {

            const texture_yz = new TextureLoader().load( texture.url );
            const texture_xz = new TextureLoader().load( texture.url );
            const texture_xy = new TextureLoader().load( texture.url );

            this.textures = [texture_yz,texture_yz, texture_xz,texture_xz, texture_xy, texture_xy ];
        }
        if(!this.offsets) this.offsets = new Array(6).fill(1)
            .map(() => ([Math.random(), Math.random()]));

        this.materials = this.textures.map((t, i) => {
                t.wrapS = RepeatWrapping;
                t.wrapT = RepeatWrapping;
                t.offset.set(...this.offsets[i]);
                return new THREE.MeshLambertMaterial({map: t})
            }
        );
        this.mesh.material = this.materials;
        this.scaleTextures();
    }

    scaleTextures( {x, y, z} = this.mesh.scale){
        const textures = this.materials.map(({ map }) => map);
        const texture_yz = textures[0];
        const texture_xz = textures[2];
        const texture_xy = textures[4];

        texture_xy.repeat.set(x / 300, y / 300);
        texture_yz.repeat.set(z / 300, y / 300);
        texture_xz.repeat.set(x / 300, z / 300);
    }

    dispose(){
        if(this.geometry) this.geometry.dispose();
        if(this.materials) this.materials.forEach(m => m.dispose());
    }

    hide(){
        hideMesh(this.mesh);
    }
}
