import Model from "./model";
import ComponentLoader from "../yaml-processing/ComponentLoader";
import ThreeD from "../yaml-processing/ThreeD";
import LIB_MODELS from "./yamls";
import {makeBox, translateBox, hideMesh, interpretRotationXYZ, TRIPLE_ZERO} from "../helpers";
import PrimitiveModel from "./primitive-model";

function *iterateNodes(node) {
    if (Array.isArray(node)) {
        for (const child of node) {
            yield* iterateNodes(child);
        }
    } else {
        if (node.children) {
            for (const child of node.children) {
                yield* iterateNodes(child);
            }
        } else {
            yield node;
        }
    }
}

function countNodes(node, target = {}){
    if(!node.name) return;
    if(!target[node.name]) {
        target[node.name] = 1;
    } else {
        target[node.name] += 1;
    }
    if(node.children){
        node.children.forEach((child) => { countNodes(child, target)} )
    }
    return target;
}


function validURL(str) {
    return str.startsWith("http");
    // const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    // return !!pattern.test(str);
}


export default class LibraryModel extends Model {

    ModelComponent;
    params;
    meshMap = {};
    scene;

    textureStock = {};

    constructor(id, config) {
        super(id);
        this.config = config;
        this.setYAML();
        this.load();
    }

    setYAML(){
        const { object } = this.config;
        this.yamlModel = LIB_MODELS[object || "default"];
    }

    load(){
        if(!this.yamlModel) return;
        ComponentLoader.load(this.yamlModel);
        const { id, config } = this;
        const { params, translate, rotate } = config;
        this.params = params;
        this.ModelComponent = ThreeD.get(config.object);
        this.pos = {...TRIPLE_ZERO, ...translate};
        this.rot = {...TRIPLE_ZERO, ...interpretRotationXYZ(rotate || TRIPLE_ZERO)};

        this.model = this.ModelComponent(params,
            this.pos,
            this.rot,
            id);


    }

    getConfig() {
        const conf = super.getConfig();
        const partsCount = this.model ? countNodes(this.model) : {};
        return {...conf, partsCount };
    }

    addTo(scene) {
        this.scene = scene;
        this.inSceneYet = false;
        if(!this.model) return;
        const arr = []
        for (const node of iterateNodes(this.model)) {
            arr.push(node)
           this.addMeshNode(node);
        }
        this.inSceneYet = true;
    }

    addMeshNode(node){
        const { key, ...config } = node;
        let texture ;
        if (validURL(config.parameters.color)){
            texture = config.parameters.color;
        };

        const m = new PrimitiveModel(key, { ...config,
            texture: { url: texture, preloaded: this.textureStock[texture] || null }, ...config.parameters,
            translate: config.translation });
        this.meshMap[node.key] = m;
        if(texture && ! this.textureStock[texture]) this.textureStock[texture] = m.textures;
        this.scene.add(m.mesh);
    }

    update(updObj){
        const { params, translate } = updObj;
        if(params) {
            this.params = {...this.params, ...params};
            this.config.params = {...this.config.params, ...params};

        }
        if(translate){
            this.pos = { ...this.pos, ...translate};
        }

        this.model = this.ModelComponent(this.params,
            this.pos,
            this.rot,
            this.id);

        this.updateMeshes();

    }

    updateMeshes(){
        const unusedKeys = Object.keys(this.meshMap);

        for (const node of iterateNodes(this.model)) {

            if(this.meshMap[node.key]) {
                const part = this.meshMap[node.key];
                // geometry
                const { width, depth, height, translation, color, origin } = node.parameters;
                part.mesh.scale.x = width;
                part.mesh.scale.y = height;
                part.mesh.scale.z = depth;
                //
                translateBox({
                    translation, size: { width, depth, height },
                    origin,
                    target: part.mesh.position
                });
                // texture
                if(node.parameters.color !== part.config.parameters.color){
                    part.setTexture({
                        url: node.parameters.color,
                        preloaded: this.textureStock[node.parameters.color] || null
                    });
                    part.config.parameters.color = node.parameters.color;
                    if(!this.textureStock[node.parameters.color]) {
                        this.textureStock[node.parameters.color] = part.textures;
                    }
                }
                unusedKeys.splice(unusedKeys.indexOf(node.key), 1);
            } else {
                this.addMeshNode(node);
            }
        }
        for (const key of unusedKeys) {
            hideMesh(this.meshMap[key].mesh);
        }
    }

    dispose(){

        Object.values(this.meshMap).forEach(m => m.dispose());
    }

    select() {

        for (const node of iterateNodes(this.model)) {
            this.meshMap[node.key].materials.forEach((m) => {
                m.transparent = false;
                m.opacity = 1
            });
        }
    }

    unselect(){

        for (const node of iterateNodes(this.model)) {
            this.meshMap[node.key].materials.forEach((m) => {
                m.transparent = true;
                m.opacity = 0.2
            });
        }
    }

    hide(){
        Object.keys(this.meshMap).forEach((key) => hideMesh(this.meshMap[key].mesh))
    }
}
