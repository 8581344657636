import * as THREE from "three";
import TemplateInterpreter from "../core/TemplateInterpreter";
import AbstractObjectBuilder from "../core/AbstractObjectBuilder";
import AbstractObjectFactory from "../core/AbstractObjectFactory";
import * as evaluation from "../helpers/evaluation";

const CM_TO_MM = 10;
const M_TO_MM = 1000;
const INCH_TO_MM = 25.4;
const FEET_TO_MM = 304.8;

const SourceUnitsScale = {
    mm: 1,
    cm: CM_TO_MM,
    m: M_TO_MM,
    in: INCH_TO_MM,
    ft: FEET_TO_MM,
};

export default class FileInterpreter extends TemplateInterpreter {

    static async interpret(template, context, pt, Library, key) {
        const obj = await Library.loadFBX(template.fileURL);
        const transformations = this.interpretTransformations(template, context, pt);
        const spec = {
            material: undefined,
            parts: {},
            scale: SourceUnitsScale[template.sourceUnits] || 1,
        };
        if (template.material) {
            spec.material = evaluation.evaluateExpressionMap(template.material, context);
        }
        if (template.parts) {
            for (const partName in template.parts) {
                const part = template.parts[partName];
                spec.parts[partName] = {
                    material: evaluation.evaluateExpressionMap(part.material, context),
                };
            }
        }
        return new FileFactory(obj, spec, transformations, key);
    }

}

class FileFactory extends AbstractObjectFactory {

    constructor(readonly object, readonly spec, readonly t, readonly k) {
        super();
    }

    * generate(container) {
        yield {
            object: new FileBuilder(this.object, this.t, this.k, this.spec),
            container,
        };
    }

}

class FileBuilder extends AbstractObjectBuilder {

    constructor(
        readonly object,
        readonly transformations,
        readonly key,
        readonly spec,
    ) {
        super();
    }

    findHost(container) {
        return undefined;
    }

    construct() {
        const obj = this.object.clone();

        if (this.spec.scale !== 1) {
            obj.children[0].scale.set(this.spec.scale, this.spec.scale, this.spec.scale);
        }

        if (this.spec.material) {
            const { color, opacity } = this.spec.material;
            // const material = new THREE.MeshPhongMaterial(
            //     {
            //         color,
            //         opacity,
            //         transparent: opacity < 1,
            //     },
            // );
            const material = obj.getObjectByProperty("type", "Mesh").material;
            material.color = new THREE.Color(color);
            material.opacity = opacity;
            material.transparent = opacity < 1;
        }

        for (const partName in this.spec.parts) {
            const { color, opacity } = this.spec.parts[partName].material;
            console.log(partName, color, opacity);
            const partObj = obj.getObjectByName(partName);
            partObj.material.dispose();
            partObj.material = new THREE.MeshPhongMaterial(
                {
                    color,
                    opacity,
                    transparent: opacity < 1,
                },
            );
        }

        return obj;
    }

    apply(object) {
    }

}
