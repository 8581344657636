/**
 * Collection of virtual components
 */
export default class ComponentLibrary {
    static components = {};

    /**
     * Add component to collection
     * @param {string} name
     * @param {VirtualComponent} component
     */
    static add(name, component) {
        this.components[name] = component;
    }

    /**
     * Get component
     * @param {string} name
     * @returns {VirtualComponent | undefined}
     */
    static get(name) {
        return this.components[name];
    }
}
