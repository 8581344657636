export const ReferenceWithTranslationToCuboid = {
    kind: "Reference",
    template: "Cuboid",
    translation: {
        x: 100,
        y: 200,
        z: 400,
    },
};

export const ReferenceToPrimitiveWithTranslation = {
    kind: "Reference",
    template: "PrimitiveWithTranslation",
};

export const ReferenceWithTranslationToPrimitiveWithTranslation = {
    kind: "Reference",
    template: "PrimitiveWithTranslation",
    translation: {
        x: 100,
        y: 200,
        z: 400,
    },
};

export const ReferenceWithOppositeTranslationToPrimitiveWithTranslation = {
    kind: "Reference",
    template: "PrimitiveWithTranslation",
    translation: {
        x: -100,
        y: -200,
        z: -400,
    },
};

export const ReferenceWithRotationAroundXBy45ToCuboid = {
    kind: "Reference",
    template: "Cuboid",
    rotation: {
        x: 45,
    },
};

export const ReferenceToPrimitiveWithRotationAroundXBy45 = {
    kind: "Reference",
    template: "PrimitiveWithRotationAroundXBy45",
};

export const ReferenceWithRotationAroundXBy30ToPrimitiveWithRotationAroundXBy45 = {
    kind: "Reference",
    template: "PrimitiveWithRotationAroundXBy45",
    rotation: {
        x: 30,
    },
};

export const ReferenceWithRotationAroundYBy45ToPrimitiveWithRotationAroundXBy45 = {
    kind: "Reference",
    template: "PrimitiveWithRotationAroundXBy45",
    rotation: {
        y: 45,
    },
};
