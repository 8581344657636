import Model from "./model";

// import roof from "../../../../../assets/fbx/TEST_roof.fbx"
// import floor from "../../../../../assets/fbx/TEST_floor.fbx"
// import walls from "../../../../../assets/fbx/TEST_walls.fbx"
// import hospital from "../../../../../assets/fbx/testFbxFiles.fbx"

// import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import * as THREE from "three";
// import * as CONSTANTS from "../../constants";
// import {interpretRotation} from "../helpers";

const materials = [
new THREE.MeshLambertMaterial(
    { emissive: 0x3a3a3a,
        color: "#aed1cb"})
]


export default class ExportedModel extends Model {

    meshes = [];

    constructor(id, config) {
        super(id);
        const { src, params, object } = config;
        //
        // if(object === "roof"){ this.src = roof; }
        // if(object === "floor"){ this.src = floor; }
        // if(object === "walls"){ this.src = walls }
        // if(object === "hospital") { this.src = hospital }

        this.params = config;

    }

    addTo(scene){
        this.load(() => {
            this.mesh.forEach(m => {
                const material = materials[Math.floor(Math.random() * materials.length)];
                if (this.params.rotate) {
                    m.rotation.x = interpretRotation(this.params.rotate.x);
                    m.rotation.z = interpretRotation(this.params.rotate.y);
                    m.rotation.y = interpretRotation(this.params.rotate.z);
                }
                if( m.type === "Group"){
                    this.meshes = [...this.meshes, ...m.children.filter(({ type }) => type === "Mesh")]
                        .map((me) => {
                            me.material = material;
                            return me;
                        } )

                    m.children.filter(({ type }) => type === "AmbientLight").forEach(a => a.visible = false);

                }
                scene.add(m)
            });
        });
    }

    load(callback){
        const { src } = this;
        const loader = new FBXLoader();
        loader.load(src, (data) => {
            const model = [data];
            // const model = data.children.filter(({ type }) => type === "Mesh" || type === "Group");
            this.mesh = model;
            for(const [key, val] of Object.entries(this.params)){
                this.setAttribute(key, val);
            };
            window.gltf = model;
            callback();
        });
    }

    update(updObj){
        const { params } = updObj;
        for(const [param, val] of Object.entries(params)){
            this.setAttribute(param, val);
        }
        this.params = {...this.params, ...params};
    }

    setAttribute(key, val){
       // const { morphTargetDictionary, morphTargetInfluences } = this.mesh;
       // morphTargetInfluences[morphTargetDictionary[key]] = val;
    }

}
