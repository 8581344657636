import Facade from "./core";
import { difference } from './helpers';
import cloneDeep from "lodash.clonedeep";

const Sandy = (function() {
    let config = {};
    let facade = new Facade();
    let notify = null;

    function setConfig(c){
        config = cloneDeep(c);
    }

    function init(conf){
        window.initScene = conf;
        setConfig(conf);
        if(!config.hasOwnProperty("scenes") || config.empty) return;
        Object.entries(config.scenes).forEach(([ key, scene]) => {
            facade.addScene(key, scene);
        })
    }

    function change(conf) {
        const diff = difference(conf, config);
        if(diff && Object.keys(diff).length === 0 && diff.constructor === Object) return;
        Object.entries(diff.scenes).forEach(([ key, d]) => {
            facade.changeScene(key, d);
        })
        setConfig(conf);
    }

    function setNotification(fn){
        notify = fn;
        facade.setNotification((c) => {
            config = c;
            fn(c);
        });
    }

    function resetConfig(c){
        facade.finalize();
        init(c);
    }

    return {
        init,
        change,
        setNotification,
        resetConfig
    };
})();

export default Sandy;
