const defaultColor = "#106161";

export default class Model {

    geometry;
    materials;
    mesh;

    constructor(id, config) {
        this.id = id;
        this.config = config;
    }

    getConfig(){
        return this.config;
    }

    addTo(scene){
        scene.add(this.mesh);
    }

    setName(name){
        this.mesh.name = name;
    }

    setColor(color){
        if(this.materials) this.materials.forEach((m) =>  m.setValues({ color: color }))
    }

    setHovered(set){
        this.setColor(set ? "#d7f9fa" : defaultColor);
    }

    select(color){
        this.setColor(color || defaultColor);
    }

    unselect(){
       this.setColor(defaultColor);
    }

    setPosition(xi,yi,zi) {

            this.mesh.position.x = xi;
            this.mesh.position.y = yi;
            this.mesh.position.z = zi;
    }

    setScale(x,y,z){
        this.mesh.scale.x = x;
        this.mesh.scale.y = y;
        this.mesh.scale.z = z;
        if(this.textures && this.scaleTextures) this.scaleTextures();
    }

    setTexture(){}

    updateConfig(){
        const { x, y, z } = this.mesh.position;
        this.config.translate = { x, y, z };
    }

    update(updObj){

        const { params, translate, texture } = updObj;
        if(params){
            this.setScale(...Object.values({
                x: params.width || this.mesh.scale.x,
                y: params.height || this.mesh.scale.y,
                z: params.depth || this.mesh.scale.z,
            }))
        }
        if(translate){
            this.setPosition(...Object.values({
                x: translate.x || this.mesh.scale.x,
                y: translate.y  || this.mesh.scale.y,
                z: translate.z  || this.mesh.scale.z,
            }))
        }
        if(texture){
            this.setTexture(texture);
        };
    }


    dispose(){
        if(this.geometry) this.geometry.dispose();
        if(this.material) this.material.dispose();
    }
}

