import React from "react";
import styled from "styled-components";
import { useEffect, useRef } from "react";

import { onMessage, setPort } from "./LegacyApp";

const StyledDiv = styled.div`
    width: 100%;
    height: 100%;
`;

export default function App() {
    const messageChannel = useRef(new MessageChannel());

    useEffect(
        () => {
            if (window.self === window.parent) {
                return;
            }
            setPort(messageChannel.current.port1);
            messageChannel.current.port1.onmessage = onMessage;
            window.parent.postMessage(
                "engine-3d.initialized",
                "*",
                [messageChannel.current.port2],
            );
        },
        [],
    );

    return <StyledDiv id="sandy" />;
}
