import TemplateInterpreter from "../core/TemplateInterpreter";
import * as evaluation from "../helpers/evaluation";
import AbstractObjectFactory from "../core/AbstractObjectFactory";

export default class DisableInterpreter extends TemplateInterpreter {

    static async interpret(template, context, pt, Library, interpret) {
        const disable = evaluation.evaluateExpressionList(template.disable || [], context);
        if (disable.includes(true)) {
            return new DisableFactory();
        }
        return interpret(template, { ...context, _DISABLE: true }, pt, Library);
    }

}

class DisableFactory extends AbstractObjectFactory {

    * generate(container) {}

}
