import TemplateInterpreter from "../core/TemplateInterpreter";
import * as expansion from "../helpers/expansion";
import AbstractObjectFactory from "../core/AbstractObjectFactory";

export default class IterableInterpreter extends TemplateInterpreter {

    static async * interpretChildren(template, context, pt, Library, interpret) {
        const iterations = expansion.expandIterators(template.iterators, context);
        for (const iteration of iterations) {
            const iterationContext = {
                ...iteration,
                ...context,
                _ITERATORS: true,
            };
            yield interpret(template, iterationContext, pt, Library, JSON.stringify(iteration));
        }
    }

    static async interpret(template, context, previTrans, Library, interpret) {
        const iterables = [];
        const generator = this.interpretChildren(template, context, previTrans, Library, interpret);
        for await (const child of generator) {
            iterables.push(child);
        }
        return new IterableFactory(iterables);
    }

}

class IterableFactory extends AbstractObjectFactory {

    constructor(readonly iterables) {
        super();
    }

    * generate(container) {
        for (const iterable of this.iterables) {
            yield {
                object: iterable,
                container,
            };
        }
    }

}
