import type { Object3D } from "three";
import * as unitConversion from "../helpers/unitConversion";

export default abstract class AbstractObjectBuilder {

    public object;

    abstract findHost(container: Object3D): Object3D

    abstract construct(): Object3D

    abstract apply(object: Object3D): void

    protected transformations;

    applyTransformations(object: Object3D): void {
        const { translation = {} } = this.transformations;
        object.position.set(
            translation.x || 0,
            translation.y || 0,
            translation.z || 0,
        );

        const { rotation = {} } = this.transformations;
        object.rotation.set(
            unitConversion.degToRad(rotation.x) || 0,
            unitConversion.degToRad(rotation.y) || 0,
            unitConversion.degToRad(rotation.z) || 0,
        );
    }

}
