export const ReferenceWithOneIteratorAndTranslationToCuboid = {
    kind: "Reference",
    template: "Cuboid",
    iterators: {
        i: {
            start: 0,
            step: 1,
            stop: 10,
        },
    },
    translation: {
        x: "i * (500 + 10)",
    },
};

export const ReferenceWithTwoIteratorsAndTranslationToCuboid = {
    kind: "Reference",
    template: "Cuboid",
    iterators: {
        i: {
            start: 0,
            step: 1,
            stop: 10,
        },
        j: {
            start: 0,
            step: 1,
            stop: 10,
        },
    },
    translation: {
        x: "i * (500 + 10)",
        y: "j * (300 + 10)",
    },
};

export const ReferenceWithOneIteratorAndTranslationToPrimitiveWithOneIteratorAndTranslation = {
    kind: "Reference",
    template: "PrimitiveWithOneIteratorAndTranslation",
    iterators: {
        xyz: {
            start: 0,
            step: 1,
            stop: 10,
        },
    },
    translation: {
        y: "xyz * (500 + 10)",
    },
};
