import * as THREE from "three";

import TemplateInterpreter from "../../core/TemplateInterpreter";
import AbstractObjectBuilder from "../../core/AbstractObjectBuilder";
import * as evaluation from "../../helpers/evaluation";
import AbstractObjectFactory from "../../core/AbstractObjectFactory";
import GarbageCollector from "../../GarbageCollector";

export default class CuboidInterpreter extends TemplateInterpreter {

    static interpretSpecification(template, context) {
        return {
            name: template.name,
            geometry: evaluation.evaluateExpressionMap(template.geometry, context),
            material: evaluation.evaluateExpressionMap(template.material, context),
            origin: evaluation.evaluateExpression(template.origin, context),
        };
    }

    static async interpret(template, context, previousTransformations, Library, interpret, key) {
        const specification = this.interpretSpecification(template, context);
        const transformations = this.interpretTransformations(template, context, previousTransformations);
        return new CuboidFactory(specification, transformations, key);
    }

}

class CuboidFactory extends AbstractObjectFactory {

    constructor(readonly s, readonly t, readonly key) {
        super();
    }

    * generate(container) {
        yield {
            object: new CuboidBuilder(this.s, this.t, this.key),
            container,
        };
    }

}

export class CuboidBuilder extends AbstractObjectBuilder {

    constructor(
        readonly specification,
        readonly transformations,
        readonly key,
    ) {
        super();
        if (!this.specification.material.opacity) {
            this.specification.material.opacity = 1;
        }
    }

    findHost(root) {
        return root.children.find((child) => child.userData.key === this.key);
    }

    construct() {
        // region geometry
        let { depth, height, width } = this.specification.geometry;
        const geometry = new THREE.BoxGeometry(width, height, depth);

        const { origin } = this.specification;
        if (origin === "Nil") geometry.translate(width / 2, height / 2,  depth/ 2);
        if (origin === "BaseCenter") geometry.translate(0, height / 2, 0);
        // endregion

        // region material
        const { color, opacity } = this.specification.material;
        const material = new THREE.MeshPhongMaterial(
            {
                color,
                opacity,
                transparent: opacity < 1,
            },
        );
        // endregion

        // region mesh
        const mesh = new THREE.Mesh(geometry, material);

        const { name } = this.specification;
        mesh.name = name;
        // endregion mesh

        // region outline
        const edges = new THREE.EdgesGeometry(geometry);
        const line = new THREE.LineSegments(
            edges,
            new THREE.LineBasicMaterial(
                {
                    color: 0x000000,
                    // opacity: .1,
                    // transparent: true,
                },
            ),
        );
        mesh.add(line);
        GarbageCollector.protect(line, mesh);
        // endregion outline

        // region userData
        mesh.userData.key = this.key;
        // endregion

        return mesh;
    }

    apply(object) {
        // region geometry
        let geometryChanged = false;
        let { depth, height, width } = this.specification.geometry;
        if (
            object.geometry.parameters.width === width
            && object.geometry.parameters.height === depth
            && object.geometry.parameters.depth === height
        ) {} else {
            object.geometry.dispose();
            object.geometry = new THREE.BoxGeometry(width, depth, height);
            geometryChanged = true;
        }
        // endregion

        // region material
        let { color, opacity } = this.specification.material;
        if (
            object.material.color.equals(new THREE.Color(color))
            && object.material.opacity === opacity
        ) {} else {
            object.material.dispose();
            object.material = new THREE.MeshPhongMaterial(
                {
                    color,
                    opacity,
                    transparent: opacity < 1,
                },
            );
        }
        // endregion

        // region outline
        if (geometryChanged) {
            const line = object.children[0];
            line.geometry.dispose();
            line.geometry = new THREE.EdgesGeometry(object.geometry);
        }
        // endregion
    }

}
