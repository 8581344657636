import React, { useEffect, useRef } from "react";
import * as THREE from "three";

import useApi from "../dev/useApi";
import useLibrary from "./library/useLibrary";
import * as sceneHelpers from "./helpers/scene";
import * as Renderer from "./Renderer";
import * as Examples from "./interpreters/test";
import { ReferenceWithDefaultsToPrimitiveWithDefaults } from "./interpreters/test";

export default function Dev2() {
    const canvas = useRef();
    const Library = useLibrary();
    const { subscribe } = useApi();

    useEffect(
        () => {
            THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);
            const renderer = new THREE.WebGLRenderer(
                {
                    alpha: true,
                    antialias: true,
                    canvas: canvas.current,
                },
            );
            const camera = sceneHelpers.makeCamera(renderer.domElement);
            camera.position.set(1000, -3000, 1000);
            const controls = sceneHelpers.makeOrbitControls(camera, renderer.domElement);
            const scene = new THREE.Scene();
            scene.add(sceneHelpers.makeAmbientLight());
            renderer.render(scene, camera);

            const worldGroup = new THREE.Group();
            worldGroup.name = "World Group";
            scene.add(worldGroup);

            async function onMessage(request) {
                console.log(request);
                const Lib = Library.withLocalTemplates(request.templates);
                const builder = await Renderer.interpret(request.spec, request.parameters, undefined, Lib, request.id);
                const renderTarget = Renderer.getRenderTarget(worldGroup, request.id.toString());
                Renderer.render(builder, renderTarget);
                // renderTarget.add(new THREE.BoxHelper(renderTarget, 0x00ffff));
            }

            async function requestRenders() {
                await onMessage(
                    {
                        spec: {
                            name: "AxisCross___",
                            kind: "Group",
                            axisCross: 1000,
                            children: [],
                        },
                        parameters: {},
                        id: "ac",
                    },
                );
                // await onMessage(
                //     {
                //         spec: Examples.ReferenceWithParametersToPrimitiveWithDefaults,
                //         parameters: {},
                //         id: "ThisIsID",
                //     },
                // );
                // await onMessage(
                //     {
                //         spec: {
                //             name: "hha",
                //             kind: "Reference",
                //             template: "A_testFBX1",
                //         },
                //         parameters: {},
                //         templates: {
                //         },
                //         id: "ThisIsID2",
                //     },
                // );
                // @ts-ignore
                const urlTemplate = (new URL(document.location)).searchParams.get("template");
                if (urlTemplate) {
                    await onMessage(
                        {
                            spec: {
                                name: "url",
                                kind: "Reference",
                                template: urlTemplate,
                            },
                            parameters: {},
                            templates: {},
                            id: "urlTemplate",
                        }
                    );
                }
            }
            requestRenders();

            subscribe({ onMessage });

            function render() {
                requestAnimationFrame(render);

                if (sceneHelpers.resizeRenderer(renderer)) {
                    const canvas = renderer.domElement;
                    camera.aspect = canvas.clientWidth / canvas.clientHeight;
                }

                camera.updateProjectionMatrix();
                controls.update();

                renderer.render(scene, camera);
            }
            requestAnimationFrame(render);
        },
        [],
    );

    return (
        <div
            style={ { height: "100vh", width: "100vw" } }
        >
            <canvas
                ref={ canvas }
                style={ { height: "100%", width: "100%" } }
            />
        </div>
    );
}
