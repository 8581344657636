import { useApolloClient } from "@apollo/client";
import { useEffect, useRef } from "react";

// @ts-ignore
import FetchTemplate from "./FetchTemplate.graphql";
import parse from "./parse";
import * as Examples from "../../interpreters/test";

export default function useTemplateLibrary() {
    const apolloClient = useApolloClient();
    const TemplateLibrary = useRef({});

    useEffect(
        () => {
            Object.assign(TemplateLibrary.current, Examples);
        },
        [],
    );

    function libraryHas(templateName) {
        return Boolean(TemplateLibrary.current[templateName]);
    }

    function libraryGet(templateName) {
        return TemplateLibrary.current[templateName];
    }

    function libraryWrite(templateName, template) {
        TemplateLibrary.current[templateName] = template;
    }

    async function fetchTemplate(templateName) {
        const response = await apolloClient.query(
            {
                query: FetchTemplate,
                variables: {
                    name: templateName,
                },
            },
        );
        const modelMap = response?.data?.modelFullDefinition || {};
        Object
            .keys(modelMap)
            .map((key) => [key, modelMap[key].definition])
            .map(([templateName, template]) => parse(templateName, template))
            .forEach((template) => libraryWrite(template.name, template));
    }

    return async function loadTemplate(templateName) {
        if (!libraryHas(templateName)) {
            await fetchTemplate(templateName);
        }
        return libraryGet(templateName);
    };
}
