import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Iframe = styled.iframe`
    border: none;
    height: 100%;
    width: 100%;
`;

let port1;

let canUpdate = false;

function onMessage(event) {
    console.log("ONMESSSAAAAAGE");
    console.log(event);
}

function makeSpec(conf) {
    return {
        kind: "Reference",
        template: Object.values(conf.scenes.scene.models)[0].object,
    };
}

function localTemplates(conf) {
    const x = Object.values(conf.scenes.scene.models)[0];
    return {
        [x.object]: x.dev[x.object],
    };
}

function onEngineInit(event) {
    if (typeof event.data === "object") {
        if (event.data.source === "engine-3d" && event.data.type === "engine.initialized") {
            port1 = event.ports[0];
            port1.onmessage = onMessage;
            canUpdate = true;
        }
    }
}

const ENGINE3D_URL = "https://engine-3d.app.bimproject.cloud/?debug&lighting&overlay=all&stats&worldAxesHelper";

export default function Ifr({ config }) {
    const iframeRef = useRef();

    useEffect(
        () => {
            function onIframeInit(event) {
                onEngineInit(event, config);
            }

            window.addEventListener("message", onIframeInit);
            return () => window.removeEventListener("message", onIframeInit);
        },
        [],
    );

    useEffect(
        () => {
            if (port1) {
                port1.postMessage(
                    {
                        id: "NO-IDENTIFIER",
                        source: "threed",
                        template: Object.values(localTemplates(config))[0],
                        templateLibrary: "https://engine.3d.bimproject.cloud/graphql",
                        type: "render",
                    },
                );
            }
        },
        [config],
    );

    return (
        <Iframe
            ref={ iframeRef }
            src={ ENGINE3D_URL }
        />
    )
}
