import {gql} from "@apollo/client";

export default {
     GET_SCENE: gql`
        query Scene($scene: [ModelDefinitionInput]!) {
            sceneDefinition(scene: $scene)
        },
    `,
    SAVE_GEOMETRIES: gql`
        mutation SaveGeometries($geometries: [SaveGeometryInput]!) {
            saveGeometries(input: $geometries){
                nodes {
                    name
                    _id
                    definition
                }
            }
        }
    `,
    GET_MODELS : gql`
        query Models($after: String, $before: String, $limit: Int!) {
            getAllModels(input: { limit: $limit, after: $after, before: $before }){
                name
                nodes {
                    _id
                    tags
                }
            }
        },
    `,
    GET_MODEL_MINIMAL_DEFINITION : gql`
        query Model($modelID: String, $name: String, $tag: String) {
            modelDefinition(input: { modelID: $modelID, name: $name, tag: $tag }){
                _id
                name
                definition
                defaultParams
            }
        },
    `,
    GET_MODEL_FULL_DEFINITION : gql`
        query Model ($name: String, $tag: String) {
            modelFullDefinition(input: { name: $name, tag: $tag })
        }
    `,
    QueryObjectTemplateRevisions: gql`
        query QueryObjectTemplateRevisions($name: String!) {
            objectTemplateRevisions(
                name: $name
            ) {
                _nodes1 {
                    _id
                    tags
                }
            }
        }
    `,
    ASSIGN_TAG : gql`
        mutation AssignTag ($modelID: String!, $tag: String!) {
            assignTag(input: { modelID: $modelID, tag: $tag}){
                tag
                modelID
                _id
            }
        }
    `
}
