import PrimitiveModel from "./primitive-model";
import LibraryModel from "./library-model";
import ExportedModel from "./exported-model";
import DevModel from "./dev-model";


export function primitive(...args){
    return new PrimitiveModel(...args);
}

export function lib_preset(...args){
    return new LibraryModel(...args);
}

export function exported(...args){
    return new ExportedModel(...args);
}

export function dev(...args){
    return new DevModel(...args);
}
