import { Cuboid } from "./Cuboid";

export const PrimitiveWithOneIteratorAndTranslation = {
    ...Cuboid,
    iterators: {
        i: {
            start: 0,
            step: 1,
            stop: 10,
        },
    },
    translation: {
        x: "i * (500 + 10)",
    },
};

export const PrimitiveWithTwoIteratorsAndTranslation = {
    ...Cuboid,
    iterators: {
        i: {
            start: 0,
            step: 1,
            stop: 10,
        },
        j: {
            start: 0,
            step: 1,
            stop: 10,
        },
    },
    translation: {
        x: "i * (500 + 10)",
        y: "j * (300 + 10)",
    },
};
