import Dragging from "../controls/dragging";
import {makeModel} from "../models/helpers";

import { camera } from "./customize";

export function models(o, that){
    Object.keys(o)
        .forEach((k) => {
            const i = that.models.findIndex((x) => x.id === k);
            i === -1 ? ( () => {
                const m = makeModel(k, o[k]);
                that.models.push(m);
                m.addTo(that.scene);
            })() : that.models[i].update(o[k])
        })
}

export function removeModels(o, that){
    o.forEach((model) => {

       const modelObj = that.models.find(x => x.id === model);
         modelObj.hide();
    })
}
export function other(o, that) {
    Object.keys(o).forEach((k) => {
        if (otherChanges[k]) otherChanges[k](...arguments, that);
    })
}

const otherChanges = {
    dragging : ({ dragging: {models, highlight, mode}}, that) =>
    {
            if(Dragging[mode]) {
                if(that.dragging) that.lastDragging = that.dragging;
                that.dragging = new Dragging[mode]
                (models || that.lastDragging.models, highlight || that.lastDragging.highlight);
            }
            if(mode === "none") that.dragging = null;
    },
    selection: ({ selection : { selected }}, scene) => {
      const { selection } = scene;
     scene.selected = selected;
     selection.selected = selected;
     if(selected) selection.selectCurrent(scene);
    },
    camera: ({ camera : c }, scene ) => {
        camera(c, scene)
    }
}
