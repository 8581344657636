import { Mesh } from "three";
import type { Object3D } from "three";

function dispose(object: Object3D) {
    for (const child of object.children) {
        dispose(child);
    }
    object.clear();
    if (object instanceof Mesh) {
        object.geometry.dispose();
        object.material.dispose();
    }
}

class X {

    a = [];
    b = [];

    get containers(): Object3D[] {
        return this.a;
    }

    mark(container: Object3D, object: Object3D) {
        this.a.push(container);
        this.b.push(object);
    }

    isProtected(object: Object3D, container: Object3D): boolean {
        return this.b.includes(object);
    }

}

export default class GarbageCollector {

    static sessionID;

    static x: X;

    static get isEnabled() {
        return this.sessionID !== undefined;
    }

    static enable() {
        this.sessionID = Math.random();
        this.x = new X();
    }

    static disable() {
        this.sessionID = undefined;
        this.x = undefined;
    }

    static protect(object: Object3D, container: Object3D) {
        if (!this.isEnabled) this.enable();
        this.x.mark(container, object);
    }

    static collect() {
        for (const container of this.x.containers) {
            for (const child of container.children) {
                if (!this.x.isProtected(child, container)) {
                    dispose(child);
                    container.remove(child);
                }
            }
        }
        this.disable();
    }

}
