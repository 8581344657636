import { useEffect, useRef, useState } from "react";

export default function useApi() {
    const messageChannel = useRef(new MessageChannel());
    const [requestPool, setRequestPool] = useState([]);
    const [subscriber, subscribe] = useState(undefined);
    const [executing, setExecuting] = useState(false);

    useEffect(
        () => {
            if (window.self === window.parent) {
                return;
            }
            messageChannel.current.port1.onmessage = (event) => {
                console.log(event.data);
                setRequestPool((pool) => [...pool, event.data]);
            }
            console.log("hahahahahhahhahaa");
            try {
                console.log("will post");
                window.parent.postMessage(
                    {
                        type: "engine.initialized",
                        source: "engine-3d",
                    },
                    "*",
                    [messageChannel.current.port2],
                );
                console.log("did post");
            } catch (e) {
                console.log(e);
            }
        },
        [],
    );

    useEffect(
        () => {
            if (!subscriber || requestPool.length === 0 || executing) {
                return;
            }
            const [nextRequest,] = requestPool;
            setExecuting(true);
            subscriber
                .onMessage(nextRequest)
                .then(
                    () => {
                        setRequestPool((pool) => pool.filter((r) => r !== nextRequest));
                    },
                )
                .finally(
                    () => {
                        setExecuting(false);
                    },
                );
        },
        [requestPool, subscriber, executing],
    );

    return {
        subscribe,
    };
}
