import { Cuboid } from "./Cuboid";

export const PrimitiveWithDefaults = {
    ...Cuboid,
    defaults: {
        d: 10,
        h: 20,
        w: 40,
        c: "#123abc",
        o: .9,
    },
    geometry: {
        depth: "d",
        height: "h",
        width: "w",
    },
    material: {
        color: "c",
        opacity: "o",
    }
};

export const PrimitiveWithDefaultsAndLocals = {
    ...Cuboid,
    defaults: {
        a: 300,
    },
    locals: {
        d: "a",
        h: "a * 2",
        w: "a * 4",
    },
    geometry: {
        depth: "d",
        height: "h",
        width: "w",
    },
};
