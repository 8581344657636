export const ReferenceWithParametersToPrimitiveWithDefaults = {
    kind: "Reference",
    template: "PrimitiveWithDefaults",
    parameters: {
        d: 1000,
        h: 1000,
        w: 1000,
        c: "'#44ff00'",
        o: .2,
    },
};

export const ReferenceWithDefaultsToPrimitiveWithDefaults = {
    kind: "Reference",
    template: "PrimitiveWithDefaults",
    defaults: {
        d: 100,
        h: 200,
        o: .6,
    },
};

export const ReferenceWithDefaultsAndLocalsToPrimitiveWithDefaultsAndLocals = {
    kind: "Reference",
    template: "PrimitiveWithDefaults",
    defaults: {
        b: 25,
    },
    locals: {
        a: "b * 2",
    },
};
