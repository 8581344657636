import LibraryModel from "./library-model";
import {hideMesh} from "../helpers";

function merge (target, source) {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
}

export default class DevModel extends LibraryModel {

    setYAML(){
        this.yamlModel =  this.config.dev && Object.keys(this.config.dev).length > 0 ?
            this.config.dev : null;
    }

    update({ translate, params, dev, object }) {
        if(translate || params) {
            LibraryModel.prototype.update.apply(this, [{ translate, params }]);
        } else {
            if (dev) {
                this.yamlModel = dev;
                this.config.dev = dev;
            }
            if(object) this.config.object = object;

            // hide old model
            Object.keys(this.meshMap).forEach((key) => {
                hideMesh(this.meshMap[key].mesh);
            });

            this.load();

            this.addTo(this.scene);
            this.updateMeshes();
        }

    }
}
