import ComponentLibrary from "./ComponentLibrary";
import { createComponent, getComponent } from "./Component";

export default class ThreeD {
    /**
     * Create virtual component and add it to component library
     * @param {string} name - Name of component
     * @param {Object.<string, string | number>} [locals] - Local variables mapping
     * @param {...Object} [children] - Children of virtual component
     * @returns {VirtualComponent}
     */
    static createComponent(name, locals, ...children) {
        const Component = createComponent(name, locals, ...children);
        ComponentLibrary.add(name, Component);
        return Component;
    }

    // TODO: static createElement(type, props) {}

    /**
     * Get component
     * @param {string} name - Name of component
     * @returns {VirtualComponent | RenderComponent | undefined}
     */
    static get(name) {
        return getComponent(name);
    }
}
