import { Cuboid } from "./Cuboid";

export const PrimitiveWithTranslation = {
    ...Cuboid,
    translation: {
        x: 100,
        y: 200,
        z: 400,
    },
};

export const PrimitiveWithRotationAroundXBy45 = {
    ...Cuboid,
    rotation: {
        x: 45,
    },
};

export const PrimitiveWithRotationAroundYBy45 = {
    ...Cuboid,
    rotation: {
        y: 45,
    },
};

export const PrimitiveWithRotationAroundZBy45 = {
    ...Cuboid,
    rotation: {
        z: 45,
    },
};

export const PrimitiveWithRotationAroundXYZBy45 = {
    ...Cuboid,
    rotation: {
        x: 45,
        y: 45,
        z: 45,
    },
};
