import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

export default function useFBXLoader() {
    return loadFile;
}

async function loadFile(url) {
    const group: THREE.Group = await new Promise(
        (resolve, reject) => new FBXLoader().load(url, resolve, undefined, reject),
    );

    const commonMaterial = new THREE.MeshPhongMaterial(
        {
            color: new THREE.Color(1, 0, 0),
            opacity: 1,
            transparent: false,
        },
    );
    const commonLine = new THREE.LineBasicMaterial(
        {
            color: new THREE.Color(0, 0, 0),
            opacity: .3,
            transparent: true,
        },
    );

    for (const child of group.children) {
        if (child instanceof THREE.Mesh) {
            child.material.dispose();
            child.material = commonMaterial;
            // child.material = commonMaterial.clone();

            const edges = new THREE.EdgesGeometry(child.geometry);
            const lines = new THREE.LineSegments(edges, commonLine);
            child.add(lines);
        }
    }

    // commonMaterial.dispose();

    const container = new THREE.Group();
    container.add(group);

    return container;
}
