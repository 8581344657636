import CuboidInterpreter from "./Primitive/Cuboid";
import DisableInterpreter from "./Disable";
import FileInterpreter from "./File";
import GroupInterpreter from "./Group";
import IterableInterpreter from "./Iterable";
import ReferenceInterpreter from "./Reference";
import AxisCrossInterpreter from "./AxisCross";

export function selectInterpreter(template, context) {
    if (template.axisCross && !context._AXIS_CROSS) return AxisCrossInterpreter;
    if (template.iterators && !context._ITERATORS) return IterableInterpreter;
    if (template.disable && !context._DISABLE) return DisableInterpreter;
    if (template.kind === "Primitive") {
        if (template.primitive === "Cuboid") return CuboidInterpreter;
    }
    if (template.kind === "Reference") return ReferenceInterpreter;
    if (template.kind === "Group") return GroupInterpreter;
    if (template.kind === "File") return FileInterpreter;
    // console.log("???");
}
