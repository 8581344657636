import React from "react";

function makeSpec(conf) {
    return {
        kind: "Reference",
        template: Object.values(conf.scenes.scene.models)[0].object,
    };
}

function localTemplates(conf) {
    const x = Object.values(conf.scenes.scene.models)[0];
    const y = {
        [x.object]: x.dev[x.object],
    };
    return Object.values(y)[0];
}

function prepare(config) {
    try {
        console.log("prepare");
        const scene = localTemplates(config);
        console.log("(>(>(>(>", scene);
        if (scene.template === "box") return undefined;
        return JSON.stringify([scene]);
    } catch {
        console.log("foff", config);
        return undefined;
    }
}

export default function WC(props) {
    return (
        <engine-3d
            dev="true"
            lighting="realistic"
            scene={ prepare(props.config) }
            shadows="true"
            style={ { width: "100%" } }
            zoom="true"
        ></engine-3d>
    );
}
